import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
  Avatar,
  Alert,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useEffect, useState } from "react";
import axios from "axios";
const columns = [
  {
    field: "id",
    headerName: "รหัสสินค้า",
    width: 200,
  },
  {
    field: "itemname",
    headerName: "ชื่อสินค้า",
    width: 300,
    //editable: true,
  },
  {
    field: "itemgroupname",
    headerName: "กลุ่ม",
    width: 300,
    //editable: true,
  },
  {
    field: "image",
    headerName: "รูป",
    width: 60,
    //editable: true,
    renderCell: (params) => (
      <Avatar src={process.env.REACT_APP_URL + "/upload/" + params.value} />
    ), // renderCell will render the component
  },

  {
    field: "score",
    headerName: "คะแนน",
    type: "number",
    renderCell: (params) => Number(params.value).toLocaleString(),
  },
];

export default function UserItem() {
  const [ItemCode, setItemCode] = React.useState("");
  // const [ItemName, setItemName] = React.useState("");
  const [ItemPic, setItemPic] = React.useState(null);
  const [Data, setData] = React.useState([]);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [score, setScore] = React.useState(0);
  const [reload, setReload] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [IsStatus, setIsStatus] = React.useState("1");

  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);

  async function UpdateItemPic() {
    setLoading(true);

    // event.preventDefault();
    const formData = new FormData();
    formData.append("itempic", file);
    formData.append("itemcode", ItemCode);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_upd_itempic.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 1) {
          //GetItem();
          setReload(reload + 1);
        } else {
          alert(response.data[0].message);
        }
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
        alert(error);
        setLoading(false);
      });
  }

  async function UpdateItem() {
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_upd_item.php",
      data: { itemcode: selectedRows, score: score },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 1) {
          //GetItem();
          setReload(reload + 1);
        } else {
          alert(response.data[0].message);
        }
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
        alert(error);
        setLoading(false);
      });
  }

  async function GetItem() {
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_item.php",
      data: { itemcode: ItemCode, isstatus : IsStatus },
    })
      .then(function (response) {
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
        alert(error);
      });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    setOpen(false);
    UpdateItem();
  };

  // const handleClickOpenUpload = () => {
  //  setOpenUpload(true);
  // };

  const handleCloseUpload = () => {
    setOpenUpload(false);
  };
  const handleOkUpload = () => {
    setOpenUpload(false);
    UpdateItemPic();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
      setFile(file);
      //console.log(reader.result)
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    GetItem();
  }, [reload]);

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
         // backgroundColor: "#dedede",
          borderRadius: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">สถานะ</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={IsStatus}
                label="สถานะ"
                onChange={(e) => {
                  setIsStatus(e.target.value);
                }}
              >
                <MenuItem value={"1"}>แสดงเฉพาะสินค้าที่กำหนดคะแนน</MenuItem>
                <MenuItem value={"2"}>แสดงทั้งหมด</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <Button variant="contained" onClick={() => {setReload(reload + 1)}}>
              แสดง
            </Button>
          </Grid>
        </Grid>
        <br/>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            backgroundColor: "#ffffff",
            borderRadius: 2,
          }}
        >
          <DataGrid
            rows={Data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            checkboxSelection
            disableRowSelectionOnClick
            loading={loading}
            onRowSelectionModelChange={(data) => {
              setSelectedRows(data);
              //console.log(data);
            }}
            onCellClick={(data) => {
              //setSelectedRows(data);
              data.field === "image"
                ? setOpenUpload(true)
                : setOpenUpload(false);
              // console.log(data.row.image);
              setItemPic(data.row.image);
              setItemCode(data.row.id);
            }}
          />
          <Alert severity="info">
            คลิกเลือกเมื่อต้องการกำหนดคะแนน, คลิกที่รูปเมื่อต้องการเปลี่ยนรูป
          </Alert>
          <Button
            variant="contained"
            disabled={selectedRows.length === 0 ? true : false}
            onClick={handleClickOpen}
          >
            กำหนดคะแนน
          </Button>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Point แบตเตอรี่</DialogTitle>
        <DialogContent>
          <DialogContentText>
            สำหรับ {selectedRows.length} รายการที่เลือก
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="คะแนน"
            type="number"
            fullWidth
            onChange={(e) => {
              setScore(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUpload}
        onClose={handleCloseUpload}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          รูปแบตเตอรี่ {ItemCode}
        </DialogTitle>
        <DialogContent>
          <img src={process.env.REACT_APP_URL + "/upload/" + ItemPic} alt="" />
          <br />
          <br />
          <label htmlFor="upload-image">
            <Button variant="contained" component="span">
              เปลี่ยนรูป
            </Button>

            <input
              id="upload-image"
              hidden
              accept="image/*"
              type="file"
              onChange={handleFileUpload}
            />
          </label>
          <br />
          {imageUrl && <img src={imageUrl} alt="Uploaded Image" width="200" />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpload} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOkUpload} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
