import * as React from "react";

import logo from "./logo.png";

import {
    Grid,
    Box,
  } from "@mui/material";

export default function MemberHead() {
  return (
    <Grid container rowSpacing={0}>
      <Grid
        className="font-link"
        item
        xs={4}
        sx={{
          textAlign: "left",
        }}
      >
        <Box
          component="img"
          sx={{
            width: "100px",
          }}
          alt={"logo"}
          src={logo}
        />
      </Grid>
      <Grid
        className="font-link"
        item
        xs={8}
        sx={{
          textAlign: "right",
        }}
      >
        <Box
          width={1}
          sx={{ fontSize: "12pt", color: "blue", paddingTop: 1.3 }}
        >
          สะสมแต้มแบตเตอรี่
        </Box>
      </Grid>
    </Grid>
  );
}
