import * as React from "react";
import Box from "@mui/material/Box";
import { Avatar,Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";

const columns = [
  {
    field: "datecreate",
    headerName: "วันที่คืน",
    width: 200,
    cellClassName: 'DataGrid-link',
  },
  {
    field: "warrantyno",
    headerName: "เลขรับประกัน",
    width: 200,
  },
  {
    field: "itemcode",
    headerName: "รหัสสินค้า",
    width: 200,
  },

  {
    field: "pic_name",
    headerName: "รูป",
    width: 60,
    //editable: true,
    renderCell: (params) => (
      <Avatar src={process.env.REACT_APP_URL + "/upload/" + params.value} />
    ), // renderCell will render the component
  },
  {
    field: "score",
    headerName: "คะแนน",
    width: 100,
  },
  {
    field: "scoredate",
    headerName: "วันที่สะสมแต้ม",
    width: 200,
  },
  {
    field: "custcode",
    headerName: "รหัสลูกค้า",
    width: 100,
  },


];

export default function UserReturn() {
  const [warrantyno, setWarrantyno] = useState("");
  const [itemCode, setItemCode] = useState("");

  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [selectedRows, setSelectedRows] = useState([]);
  //const [open, setOpen] = useState(false);

  const [reload, setReload] = useState(0);

  const [openMsgBox, setOpenMsgBox] = useState(false);
  const [titleMsgBox, setTitleMsgBox] = useState('ยกเลิกรับคืน');
  const [bodyMsgBox, setBodyMsgBox] = useState('ยืนยันยกเลิกรับคืนแบตเตอรี่');


  async function UpdateItem() {
    setLoading(true);
    setOpenMsgBox(false);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_cancel.php",
      data: { warrantyno: warrantyno, itemcode: itemCode },
    })
      .then(function (response) {
       // console.log(response);
        if (response.data[0].statuscode === 1) {
          //console.log(response.data[0].message);
          setReload(reload + 1);
        } else {
          alert(response.data[0].message);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  async function GetItem() {
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_all_return.php",
      data: {},
    })
      .then(function (response) {
        //console.log(response);
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    GetItem();
  }, [reload]);


  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          backgroundColor: "#ffffff",
          borderRadius: 2,
        }}
      >
        <DataGrid
          rows={Data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          //checkboxSelection
          disableRowSelectionOnClick
          loading={loading}
          onRowSelectionModelChange={(data) => {
           // setSelectedRows(data);
            //console.log(data);
          }}
          onCellClick={(data) => {
            //setSelectedRows(data);
            data.field === "datecreate" ? setOpenMsgBox(true) : setOpenMsgBox(false);
            // console.log(data.row.image);
            setWarrantyno(data.row.warrantyno);
            setItemCode(data.row.itemcode);
            //setItemCode(data.row.id);
          }}
        />

        <Alert severity="info">คลิกวันที่คืนเมื่อต้องการยกเลิกการรับคืน</Alert>
      </Box>

      <Dialog
        open={openMsgBox}
        //onClose={}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{titleMsgBox}</DialogTitle>
        <DialogContent>
          <DialogContentText>{bodyMsgBox}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setOpenMsgBox(false)}} color="primary">
            Cancel
          </Button>
          <Button onClick={UpdateItem} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>




    </>
  );
}
