import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
  Fab,

  Alert,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";

import { useEffect} from "react";
import axios from "axios";
import "./App.css";

//import ReactQuill from "react-quill";
//import "react-quill/dist/quill.snow.css";

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 50,
    cellClassName: 'DataGrid-link',
  },
  {
    field: "groupname",
    headerName: "ชื่อกลุ่มของรางวัล",
    width: 500,
  },


];

export default function UserGroup() {
  const [id, setId] = React.useState(0);
  const [groupName, setGroupName] = React.useState("");

  const [Data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
 // const [selectedRows, setSelectedRows] = React.useState([]);
 // const [open, setOpen] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);

  

  const [reload, setReload] = React.useState(0);

  const [openDelete, setOpenDelete] = React.useState(false);
  
  const [openInfoBox, setOpenInfoBox] = React.useState(false);
  const [titleInfoBox, setTitleInfoBox] = React.useState("");
  const [bodyInfoBox, setBodyInfoBox] = React.useState("");


  async function saveDatabase() {
    setLoading(true);

    const formData = new FormData();

    formData.append("id", id);
    formData.append("groupname", groupName);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_ins_group.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 1) {
          setOpenForm(false);
          setReload(reload + 1);
        } else {
          alert(response.data[0].message);
        }
        setLoading(false);
      })
      .catch(function (error) {
        alert(error);
        setLoading(false);
      });
  }

  async function delDatabase() {
    setLoading(true);
    const formData = new FormData();
    formData.append("id", id);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_del_group.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {

        console.log(response);
        
        if (response.data[0].statuscode === 1) {
          setOpenForm(false);
          setOpenDelete(false);
          setReload(reload + 1);
        } else {
          setTitleInfoBox('ลบไม่ได้');
          setBodyInfoBox(response.data[0].message)
          setOpenDelete(false);
          setOpenInfoBox(true);
        }
        setLoading(false);
      })
      .catch(function (error) {
        alert(error);
        setLoading(false);
      });
  }



  async function GetItem() {
    //setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_group.php",
    })
      .then(function (response) {
        
        setData(response.data);
       // setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    GetItem();
  }, [reload]);

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          backgroundColor: "#ffffff",
          borderRadius: 2,
        }}
      >
        <DataGrid
          rows={Data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick
          loading={loading}
          //onRowSelectionModelChange={(data) => {
           // setSelectedRows(data);
          //}}

          onCellClick={(data) => {
            if (data.field === "id") {
              setOpenForm(true);
              setId(data.row.id);
              setGroupName(data.row.groupname);
            }
          }}
        />

        <Alert severity="info">คลิกที่รหัสเมื่อต้องการแก้ไข</Alert>
      </Box>


      <Dialog
        open={openForm}
       // onClose={handleCloseUpload}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {id === 0 ? "เพิ่ม" : "แก้ไข"}กลุ่มของรางวัล
        </DialogTitle>
        <DialogContent>
          <br />

              <TextField
                fullWidth
                label="ชื่อกลุ่มของรางวัล"
                variant="outlined"
                value={groupName}
                onChange={(e) => {
                  setGroupName(e.target.value);
                }}
              />

        </DialogContent>
        <DialogActions>
          <Button
            disabled={id === 0}
            onClick={() => setOpenDelete(true)}
            color="primary"
          >
            DELETE
          </Button>

          <Button onClick={() => setOpenForm(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={saveDatabase} color="primary">
            SAVE
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">ยืนยัน</DialogTitle>
        <DialogContent>
          <DialogContentText>ลบกลุ่มของรางวัล</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDelete(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={delDatabase} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={openInfoBox} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{titleInfoBox}</DialogTitle>
        <DialogContent>
          <DialogContentText>{bodyInfoBox}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenInfoBox(false);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>      

      <Fab
        onClick={() => {
          setOpenForm(true);
          setId(0);
          setGroupName("");

        }}
        color="primary"
        aria-label="add"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
      >
        <AddIcon />
      </Fab>
    </>
  );
}
