import * as React from "react";
import { useState, useEffect } from "react";
import "./App.css";
import Html5QrcodePlugin from "./Html5Qr";

import {
  Container,
  Box,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";


export default function UserQR(props) {
  const u = localStorage.getItem("bpsoft_score_membercode");

  const [openMsgBox, setOpenMsgBox] = useState(false);
  const [titleMsgBox, setTitleMsgBox] = useState("");
  const [bodyMsgBox, setBodyMsgBox] = useState("");

  const [decodedResults, setDecodedResults] = useState("");

  const [serialno, setSerialno] = useState("");
  const [itemcode, setItemcode] = useState("");

  const [itemname, setItemname] = useState("");
  const [picname, setPicname] = useState("");
  const [score, setScore] = useState("0");

  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [UserId, setUserId] = useState(u === null ? "" : u);

  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [Data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);



  const onNewScanResult = (decodedText, decodedResult) => {
    //console.log("App [result]", decodedResult);
    setDecodedResults(decodedText);

    //decodedText = decodedText.substr(30);
    const serial = decodedText.split(",");

    setSerialno(serial[0]);
    setItemcode(serial[1]);
    //etrieveSO();
    setOpen(true);
  };

  async function ScoreItem() {
    const formData = new FormData();
    formData.append("warrantyno", serialno + "," + itemcode);
    formData.append("itemname", itemname);
    formData.append("pic_name", picname);
    formData.append("custcode", UserId);
    formData.append("score", score);
   // formData.append("drivername", "");
   // formData.append("telephone", "");
   // formData.append("email", "");
   // formData.append("carregister", "");
   // formData.append("pic_install", file);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_return.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        //console.log(response);

        if (response.data[0].statuscode === 0) {
          //alert('ok');
          setBodyMsgBox(response.data[0].message);
          setOpenMsgBox(true);
        } else {
          //alert('failed');

          //navigate('/membermain');
          setOpen(false);
          setTitleMsgBox("สำเร็จ");
          setBodyMsgBox("รับคืนแบตเรียบร้อย");
          setOpenMsgBox(true);
        }
      })
      .catch(function (error) {
        alert(error);
      });
  }

  async function retrieveSO() {
    if (serialno === "") {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุเลขรับประกัน");
      setOpenMsgBox(true);
      return;
    }

    if (itemcode === "") {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุรหัสสินค้า");
      setOpenMsgBox(true);
      return;
    }

    /*
    if (file === null) {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุรูปติดตั้ง");
      setOpenMsgBox(true);
      return;
    }
    */
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_so.php",
      data: { warrantyno: serialno + "," + itemcode },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 1) {
          setPicname(response.data[0].pic_name);
          setItemname(response.data[0].itemname);
          // setCustCode(response.data[0].custcode);
          // setCustName(response.data[0].custname);
          // setSono(response.data[0].sono);
          // setSodate(response.data[0].sodate);
          setScore(response.data[0].score);
          setOpenConfirm(true);
        } else {
          setTitleMsgBox("ข้อมูลไม่ถูกต้อง");
          setBodyMsgBox(response.data[0].message);
          setOpenMsgBox(true);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  async function GetItem() {
    //setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_item.php",
      data: { itemcode: "", isstatus: "1" },
    })
      .then(function (response) {
        setData(response.data);
        // setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
        alert(error);
      });
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
      setFile(file);
      //console.log(reader.result)
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    GetItem();
    //=====================================================================
    const handleBeforeUnload = (event) => {
      event.preventDefault();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    //====================================================================
  }, []);

  return (
    <div>
      <br/>
      <section className="App-section">
        <div className="App-section-title"></div>

        <Html5QrcodePlugin
          fps={10}
          qrbox={250}
          disableFlip={false}
          facingMode = {{ exact: "environment" }}
          rememberLastUsedCamera = {true}
          qrCodeSuccessCallback={onNewScanResult}
        />
      </section>
      <br />
      <Button
        fullWidth
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        ป้อนเลขรับประกัน
      </Button>
      <br />
      <br />

      <Dialog
        fullScreen
        open={open}
        //onClose={()=>{setOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // className="bp-dialog"
      >
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpen(false);
              }}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              รับแบตคืน
            </Typography>
          </Toolbar>
        </AppBar>

        <Container maxWidth="xs" sx={{paddingTop:'80px'}}>

          <TextField
            fullWidth
            label="เลขรับประกัน"
            variant="outlined"
            value={serialno}
            onChange={(e) => {
              setSerialno(e.target.value);
            }}
          />

          <br />
          <br />

          <Autocomplete
            fullWidth
            onChange={(event, value) => value && setItemcode(value.id)}
            disablePortal
            id="combo-box-demo"
            options={Data}
            defaultValue={{id : itemcode}}
            getOptionLabel={(option) => option.id}
           // sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="รหัสสินค้า"
             
              />
            )}
          />

          <br />
          <Button
            disabled={loading}
            fullWidth
            variant="contained"
            onClick={retrieveSO}
          >
            ส่งข้อมูล
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                // color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
                zIndex: 1,
              }}
            />
          )}

          <br />
          <br />

          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
          >
            ไปหน้าที่แล้ว
          </Button>
          <br />
          <br />
        </Container>
      </Dialog>

      <Dialog
        open={openConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // className="bp-dialog"
      >
        <DialogContent>
          <Box
            component="img"
            sx={{
              borderRadius: 2,
              backgroundColor: "#dedede",
              width: 1,
              // textAlign: "center",
            }}
            alt={itemname}
            src={
              picname === null
                ? process.env.REACT_APP_URL + "/upload/logo.png"
                : process.env.REACT_APP_URL + "/upload/" + picname
            }
          />
          <Box
            className="font-link"
            sx={{
              width: 1,
              textAlign: "center",
              fontSize: "30pt",
              color: "blue",
            }}
          >
            {score} คะแนน
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirm(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setOpenConfirm(false);
              ScoreItem();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openMsgBox} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{titleMsgBox}</DialogTitle>
        <DialogContent>
          <DialogContentText>{bodyMsgBox}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenMsgBox(false);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
