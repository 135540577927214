import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,

  DialogActions,
  Avatar,
  Grid,
  TextField,

  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";

import { useEffect } from "react";
import axios from "axios";
const columns = [
  {
    field: "datecreate",
    headerName: "วันที่",
    width: 160,
    //editable: true,
  },
  {
    field: "id",
    headerName: "เลขรับประกัน",
    width: 300,
  },

  {
    field: "itemname",
    headerName: "ชื่อสินค้า",
    width: 200,
  },
  {
    field: "pic_install",
    headerName: "รูปติดตั้ง",
    width: 60,
    //editable: true,
    renderCell: (params) => (
      <Avatar src={process.env.REACT_APP_URL + "/" + params.value} />
    ), // renderCell will render the component
  },
  {
    field: "custname",
    headerName: "ชื่อร้านค้า",
    width: 200,
  },
  {
    field: "username",
    headerName: "ชื่อลูกค้า",
    width: 200,
  },

  {
    field: "score",
    headerName: "คะแนน",
  },
  {
    field: "bonus",
    headerName: "bonus",
    width: 250,
  },
];

export default function UserScore() {
  const [id, setId] = React.useState(0);
  const [picInstall, setPicInstall] = React.useState("");
  const [bonus, setBonus] = React.useState(false);

  const [custCode, setCustCode] = React.useState("");
  const [custName, setCustName] = React.useState("");
  const [fName, setFName] = React.useState("");
  const [warrantyNo, setWarrantyNo] = React.useState("");

  const [Data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [reload, setReload] = React.useState(0);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            fileName: "ของรางวัล",
           // delimiter: ";",
            utf8WithBom: true,
          }}
          printOptions={{
            fileName:"ของรางวัล",
            hideFooter: true,
            hideToolbar: true,
          }}          
        />
      </GridToolbarContainer>
    );
  }


  async function UpdateScore() {
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_upd_score.php",
      data: {
        id: id,
        custcode:custCode,
        bonus: bonus ? 1 : 0,
      },
    })
      .then(function (response) {
        // console.log(response);

        if (response.data[0].statuscode === 1) {
          setOpen(false);
          setReload(reload + 1);
        } else {
          alert(response.data[0].message);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  async function GetItem() {
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_all_score.php",
      data: { custname: custName, fname: fName, warrantyno: warrantyNo },
    })
      .then(function (response) {
        //console.log(response);
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    GetItem();
  }, [reload]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          //backgroundColor: "#dedede",
          borderRadius: 2,
        }}
      >
        <Grid container spacing={2}>
        <Grid item xs={3}>
            <TextField
              fullWidth
              label="เลขรับประกัน"
              variant="outlined"
              value={warrantyNo}
              onChange={(e) => {
                setWarrantyNo(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              label="ชื่อร้านค้า"
              variant="outlined"
              value={custName}
              onChange={(e) => {
                setCustName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="ชื่อลูกค้า"
              variant="outlined"
              value={fName}
              onChange={(e) => {
                setFName(e.target.value);
              }}
            />
          </Grid>


          <Grid item xs={3}>
            <Button
              variant="contained"
              onClick={() => {
                setReload(reload + 1);
              }}
            >
              แสดง
            </Button>
          </Grid>
        </Grid>

        <br />

        <Box
          sx={{
            height: "100%",
            width: "100%",
            backgroundColor: "#ffffff",
            borderRadius: 2,
          }}
        >
          <DataGrid
            rows={Data}
            columns={columns}
            slots={{
              toolbar: CustomToolbar,
            }}            
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            //checkboxSelection
            disableRowSelectionOnClick
            loading={loading}
            onRowSelectionModelChange={(data) => {
              setSelectedRows(data);
              //console.log(data);
            }}
            onCellClick={(data) => {
              if (data.field === "pic_install") {
                setOpen(true);
                setId(data.row.id);
                setCustCode(data.row.custcode);
                setPicInstall(data.row.pic_install);
                setBonus(data.row.bonus === '1' ? true : false );
              } else {
                setOpen(false);
              }
            }}
          />

          <Alert severity="info">คลิกรูปติดตั้งเพื่อระบุ Bonus</Alert>
        </Box>
      </Box>

      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">รูปติดตั้ง</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                component="img"
                width={1}
                src={process.env.REACT_APP_URL + "/" + picInstall}
                alt=""
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={bonus} />}
                label="Bonus"
                onChange={(e) => {
                  setBonus(e.target.checked);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={UpdateScore} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
