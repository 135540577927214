import * as React from "react";
import { useState } from "react";
import { Box, Paper } from "@mui/material";

import "./UserLogin.css";
import "./MemberMain.css";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import MessageIcon from "@mui/icons-material/Message";
import HistoryIcon from "@mui/icons-material/History";
import QrCode2Icon from "@mui/icons-material/QrCode2";

import MemberPage from "./MemberPage";
import MemberUse from "./MemberUse";
import MemberHis from "./MemberHis";

export default function MemberMain() {
  const [menu, setMenu] = useState(2);

  return (
    <div>
      
        <MemberPage index={menu} />
      

      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex:9999 }}
        elevation={3}
      >
        <BottomNavigation
          sx={{ paddingLeft: 2, paddingRight: 2, zIndex: 99999 }}
          className="App-bottom"
          showLabels
          value={menu}
          onChange={(event, newValue) => {
            setMenu(newValue);
          }}
        >
          <BottomNavigationAction label="หน้าแรก" icon={<HomeIcon />} />
          <BottomNavigationAction label="ประวัติ" icon={<HistoryIcon />} />
          <BottomNavigationAction label="สแกน" icon={<QrCode2Icon />} />
          <BottomNavigationAction label="แจ้งเตือน" icon={<MessageIcon />} />
          <BottomNavigationAction label="สมาชิก" icon={<PersonIcon />} />
        </BottomNavigation>
      </Paper>
    </div>
  );
}
