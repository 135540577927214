import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Grid,
  Container,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import axios from "axios";
import "./App.css";

export default function ShowWarranty() {
  const navigate = useNavigate();

  const queryParameters = new URLSearchParams(window.location.search);
  const param = queryParameters.get("k");

  const u = localStorage.getItem("bpsoft_score_membercode");
  const p = localStorage.getItem("bpsoft_score_memberpass");

  //const [custCode, setCustCode] = useState("");
  const [custName, setCustName] = useState("");
  const [itemName, setItemName] = useState("");
  const [picName, setPicName] = useState(null);
  const [drivername, setDrivername] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [carregister, setCarregister] = useState("");

  const [score, setScore] = useState(0);
  const [isshow, setIsshow] = useState(false);

  const [isOk, setIsOk] = useState(false);
  const [isOkMessage, setIsOkMessage] = useState("");

  const [isshowErr, setIsshowErr] = useState(false);

  const [errorText, setErrorText] = useState("");
  const [open, setOpen] = useState(false);
  const [errorSave, setErrorSave] = useState("");

  const [UserId, setUserId] = useState(u === null ? "" : u);
  const [passWord, setPassWord] = useState(p);

  const [openMsgBox, setOpenMsgBox] = useState(false);
  const [titleMsgBox, setTitleMsgBox] = useState("");
  const [bodyMsgBox, setBodyMsgBox] = useState("");

  async function GetPassword() {
    if (UserId === "") {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุรหัสผู้ใช้");
      return;
    }
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_check_password.php",
      data: { custcode: UserId, password: passWord },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 0) {
          setTitleMsgBox("ไม่สำเร็จ");
          setBodyMsgBox(response.data[0].message);
          setOpenMsgBox(true);
        } else {
          setTitleMsgBox("ส่งเมล์แล้ว");
          setBodyMsgBox(response.data[0].message);
          setOpenMsgBox(true);
        }
      })
      .catch(function (error) {
        alert("error:ShowWarranty");
      });
  }

  async function ScoreItem() {
    const formData = new FormData();
    formData.append("warrantyno", param);
    formData.append("itemname", itemName);
    formData.append("pic_name", picName);
    formData.append("custcode", UserId);
    formData.append("score", score);
    formData.append("drivername", drivername);
    formData.append("telephone", telephone);
    formData.append("email", email);
    formData.append("carregister", carregister);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_item.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        //console.log(response);

        if (response.data[0].statuscode === 0) {
          setErrorSave(response.data[0].message);
          setOpen(true);
        } else {
          //navigate('/membermain');
          setErrorSave("สะสมคะแนนเรียบร้อยแล้ว");
          setOpen(true);
        }
      })
      .catch(function (error) {
        alert(error);
      });
  }

  async function ScoreReturn() {
    const formData = new FormData();
    formData.append("warrantyno", param);
    formData.append("itemname", itemName);
    formData.append("pic_name", picName);
    formData.append("custcode", UserId);
    formData.append("score", score);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_return.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 0) {
          setErrorSave(response.data[0].message);
          setOpen(true);
        } else {
          setErrorSave("บันทึกรับคืนเรียบร้อยแล้ว");
          setOpen(true);

          //navigate('/usermain');
        }
      })
      .catch(function (error) {
        alert(error);
      });
  }

  async function GetUser() {
    if (UserId === "") {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุรหัสผู้ใช้");
      setOpenMsgBox(true);
      return;
    }

    if (passWord === "") {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุรหัสผ่าน");
      setOpenMsgBox(true);
      return;
    }

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_check_user.php",
      data: { custcode: UserId, password: passWord },
    })
      .then(function (response) {
        //console.log(response.data);
        if (response.data[0].statuscode === "") {
          setErrorSave("รหัสผู้ใช้หรือรหัสผ่านไม่ถูกต้อง");
          setOpen(true);
        } else {
          if (response.data[0].statuscode === "USER") {
            if (drivername === "") {
              setTitleMsgBox("");
              setBodyMsgBox("โปรดระบุชื่อผู้ซื้อ");
              setOpenMsgBox(true);
              return;
            }

            if (telephone === "") {
              setTitleMsgBox("");
              setBodyMsgBox("โปรดระบุเบอร์โทรผู้ซื้อ");
              setOpenMsgBox(true);
              return;
            }
  
            if (carregister === "") {
              setTitleMsgBox("");
              setBodyMsgBox("โปรดระบุทะเบียนรถผู้ซื้อ");
              setOpenMsgBox(true);
              return;
            }             
          }
         
          // alert(response.data[0].statuscode);
          localStorage.setItem("bpsoft_score_membercode", UserId);
          localStorage.setItem("bpsoft_score_memberpass", passWord);

          response.data[0].statuscode === "USER" ? ScoreItem() : ScoreReturn();
        }
      })
      .catch(function (error) {
        alert("error:ShowWarranty");
      });
  }

  async function GoSystem() {
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_check_user.php",
      data: { custcode: UserId, password: passWord },
    })
      .then(function (response) {
        //console.log(response.data);
        if (response.data[0].statuscode === "") {
          setErrorSave("รหัสผู้ใช้หรือรหัสผ่านไม่ถูกต้อง");
          setOpen(true);
        } else {
          // alert(response.data[0].statuscode);
          localStorage.setItem("bpsoft_score_membercode", UserId);
          localStorage.setItem("bpsoft_score_memberpass", passWord);

          response.data[0].statuscode === "USER"
            ? navigate("/membermain")
            : navigate("/usermain");
        }
      })
      .catch(function (error) {
        alert("error:ShowWarranty");
      });
  }

  async function retrieveSO() {
    //await RetrieveUser();

    //setUserId(u);
    //setPassWord(p);

    //console.log(u);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_so.php",
      data: { warrantyno: param },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 1) {
          setPicName(response.data[0].pic_name);
          setItemName(response.data[0].itemname);
          // setCustCode(response.data[0].custcode);
          setCustName(response.data[0].custname);
          // setSono(response.data[0].sono);
          // setSodate(response.data[0].sodate);
          setScore(response.data[0].score);
          setIsshow(true);

          if (response.data[0].datecreate === "") {
            setIsOk(true);
          } else {
            setIsOkMessage(response.data[0].datecreate);
          }
        } else {
          setErrorText(response.data[0].message);
          setIsshowErr(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleClose = () => {
    setOpen(false);
    if (errorSave === "บันทึกรับคืนเรียบร้อยแล้ว") {
      navigate("/usermain");
    }
    if (errorSave === "สะสมคะแนนเรียบร้อยแล้ว") {
      navigate("/membermain");
    }
  };

  useEffect(() => {
    //setUserId(u);
    //setPassWord(p);

    retrieveSO();
  }, []);

  return (
    <div>
      <br />
      <Container
        maxWidth="xs"
        className="font-link"
        sx={{
          borderRadius: 2,
          backgroundColor: "#ffffff",
          width: 1,
          // textAlign: "center",
        }}
      >
        <Box sx={{ visibility: isshowErr ? "visible" : "hidden" }}>
          <Grid
            xs={12}
            sx={{
              fontSize: "20pt",
              textAlign: "center",
            }}
          >
            {errorText}
          </Grid>
        </Box>

        <Box sx={{ visibility: isshow ? "visible" : "hidden" }}>
          <Grid
            container
            spacing={0}
            sx={{
              textAlign: "center",
            }}
          >
 

            <Box
              component="img"
              sx={{
                borderRadius: 2,
                backgroundColor: "#dedede",
                width: 1,
                // textAlign: "center",
              }}
              alt={itemName}
              src={
                picName === null
                  ? process.env.REACT_APP_URL + "/upload/logo.png"
                  : process.env.REACT_APP_URL + "/upload/" + picName
              }
            />

            <Grid
              xs={12}
              sx={{
                fontSize: "10pt",
              }}
            >
              {param}
            </Grid>

            <Grid
              xs={12}
              sx={{
                fontSize: "30pt",
              }}
            >
              {score} คะแนน
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              visibility: isOk ? "hidden" : "visible",
              textAlign: "center",
              color: "red",
            }}
          >
            <Grid item xs={12}>
              {isOkMessage}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{ visibility: isOk ? "visible" : "hidden" }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="รหัสผู้ใช้"
                variant="outlined"
                value={UserId}
                onChange={(e) => {
                  setUserId(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="password"
                fullWidth
                label="รหัสผ่าน"
                variant="outlined"
                value={passWord}
                onChange={(e) => {
                  setPassWord(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ชื่อผู้ซื้อ"
                variant="outlined"
                value={drivername}
                onChange={(e) => {
                  setDrivername(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label="เบอร์โทรผู้ซื้อ"
                variant="outlined"
                value={telephone}
                onChange={(e) => {
                  setTelephone(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="อีเมล์ผู้ซื้อ"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ทะเบียนรถผู้ซื้อ"
                variant="outlined"
                value={carregister}
                onChange={(e) => {
                  setCarregister(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <br />

          <Button fullWidth variant="contained" onClick={GetUser}>
            ส่งข้อมูล
          </Button>
        </Box>
        <br />
      </Container>
      <br />
      <Container maxWidth="xs" className="font-link">
        <Button fullWidth variant="text" onClick={GoSystem}>
          เข้าระบบ
        </Button>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button fullWidth onClick={GetPassword} variant="text">
              ลืมรหัสผ่าน
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              fullWidth
              variant="text"
              onClick={() => {
                navigate("/register", { replace: true });
              }}
            >
              สมัครสมาชิก
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"MF AUTO"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorSave}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            ตกลง
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openMsgBox} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{titleMsgBox}</DialogTitle>
        <DialogContent>
          <DialogContentText>{bodyMsgBox}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenMsgBox(false);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
