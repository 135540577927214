import * as React from "react";
import { useState, useEffect } from "react";
import { Grid, Container, Tab, Tabs, Box } from "@mui/material";

import "./UserLogin.css";
import "./MemberMain.css";

import MemberUsed from "./MemberUsed";
import MemberScore from "./MemberScore";
import axios from "axios";

import ChargingStationIcon from '@mui/icons-material/ChargingStation';
import RedeemIcon from "@mui/icons-material/Redeem";

import MemberHead from "./MemberHead";
//  use point screen
export default function MemberHis() {
  //const [score, setScore] = React.useState(0);
  const [TabIndex, setTabIndex] = useState(0);
  const [Score, setScore] = useState(0);
  const [ScoreGet, setScoreGet] = useState(0);
  const [ScoreUse, setScoreUse] = useState(0);

  const u = localStorage.getItem("bpsoft_score_membercode");
  const p = localStorage.getItem("bpsoft_score_memberpass");

  const tabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  async function CheckUser() {
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_member.php",
      data: { custcode: u, password: p },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 0) {
          window.location = "/memberlogin";
        } else {
          // setCustPic(
          //   response.data[0].custpic === ""
          //    ? "user.png"
          //   : response.data[0].custpic
          //);

          //setSelectCustCode(u);
          setScoreGet(response.data[0].score_get);
          setScoreUse(response.data[0].score_use);
          setScore(response.data[0].score);
          //setFName(response.data[0].fname);
          //setLName(response.data[0].lname);
          //setScore(response.data[0].score);

          //GetItem();
          //setIsOk(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    CheckUser();

    
      const onConfirmRefresh = function (event) {
        event.preventDefault();
       // return (event.returnValue = "Are you sure you want to leave the page?");
      };
      window.addEventListener("beforeunload", onConfirmRefresh, {
        capture: true,
      });
    


  }, []);

  return (
    <Container maxWidth="xs">

      <MemberHead/>

      <Grid container spacing={0}>
          <Grid item xs={12} sx={{ textAlign: "center" }} className="font-link">
            <Box
              sx={{
                color: "blue",
                width: 1,
                backgroundColor: "#e6e6e6",
                textAlign: "center",
                fontSize: "20pt",
              }}
            >
              ประวัติ
            </Box>
          </Grid>
        </Grid>      
      <br/>
      <Grid container spacing={0}>
        <Grid
          className="font-link"
          item
          xs={4}
          sx={{
            textAlign: "center",
            backgroundColor: "#dedede",
            padding:1
           
          }}
        >
          <Box sx={{width:1,fontSize:'10pt'}}>คะแนนทั้งหมด </Box>
          <Box sx={{width:1,fontSize:'14pt',color:'blue'}}>{ScoreGet} </Box>
          <Box sx={{width:1,fontSize:'10pt'}}>คะแนน</Box>
         
        </Grid>

        <Grid
          className="font-link"
          item
          xs={4}
          sx={{
            textAlign: "center",
            backgroundColor: "#e6e6e6",
            padding:1
          }}
        >
          <Box sx={{width:1,fontSize:'10pt'}}>ใช้ไปแล้ว</Box>
          <Box sx={{width:1,fontSize:'14pt',color:'blue'}}>{ScoreUse} </Box>
          <Box sx={{width:1,fontSize:'10pt'}}>คะแนน</Box>

        </Grid>
        <Grid
          className="font-link"
          item
          xs={4}
          sx={{
            textAlign: "center",
            backgroundColor: "#f2f2f2",
            padding:1
          }}
        >
          <Box sx={{width:1,fontSize:'10pt'}}>คงเหลือ</Box>
          <Box sx={{width:1,fontSize:'14pt',color:'blue'}}>{Score} </Box>
          <Box sx={{width:1,fontSize:'10pt'}}>คะแนน</Box>

        </Grid>
      </Grid>
      <Tabs
        value={TabIndex}
        onChange={tabChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="การแลกของรางวัล" icon={<RedeemIcon />} />
        <Tab label="แบตเตอรี่ที่สะสม" icon={<ChargingStationIcon />} />

    
      </Tabs>

      {TabIndex === 0 ? <MemberUsed /> : <MemberScore />}

      <br />
      <br />
      <br />
    </Container>
  );
}
