import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Grid,
  Container,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CardMedia,
  Link
} from "@mui/material";
import axios from "axios";
import "./App.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import CreateIcon from '@mui/icons-material/Create';


import logo from "./logo.png";

export default function Register() {
  const navigate = useNavigate();

  const [CustCode, setCustCode] = useState("");
  const [CustName, setCustName] = useState("");
  const [Password, setPassword] = useState("");
  const [Token, setToken] = useState("");
  const [Prefix, setPrefix] = useState("");
  const [FName, setFName] = useState("");
  const [LName, setLName] = useState("");
  const [Sex, setSex] = useState("");

  const [Addr, setAddr] = useState("");
  const [Tel, setTel] = useState("");
  const [Email, setEmail] = useState("");
  const [open, setOpen] = React.useState(false);
  const [messageBoxTitle, setMessageBoxTitle] = React.useState("");
  const [messageBoxBody, setMessageBoxBody] = React.useState("");
  const [registerd, setRegisterd] = React.useState(false);

  const [openMsgBox, setOpenMsgBox] = useState(false);
  const [titleMsgBox, setTitleMsgBox] = useState("");
  const [bodyMsgBox, setBodyMsgBox] = useState("");

  const [NewDay, setNewDay] = useState(1);
  const [NewMonth, setNewMonth] = useState(1);
  const [NewYear, setNewYear] = useState(2500);

  const [agree, setAgree] = React.useState(false);
  const [openAgree, setOpenAgree] = React.useState(false);

  let i = 0;
  let days = [];
  for (i = 1; i <= 31; i++) {
    days.push(i);
  }

  let years = [];
  for (i = 2500; i <= 2550; i++) {
    years.push(i);
  }
  let months = [
    { id: 1, name: "มกราคม" },
    { id: 2, name: "กุมภาพันธ์" },
    { id: 3, name: "มีนาคม" },
    { id: 4, name: "เมษายน" },
    { id: 5, name: "พฤษภาคม" },
    { id: 6, name: "มิถุนายน" },
    { id: 7, name: "กรกฎาคม" },
    { id: 8, name: "สิงหาคม" },
    { id: 9, name: "กันยายน" },
    { id: 10, name: "ตุลาคม" },
    { id: 11, name: "พฤศจิกายน" },
    { id: 12, name: "ธันวาคม" },
  ];

  async function saveDatabase() {
    if (CustCode === "") {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุ Username");
      setOpenMsgBox(true);
      return;
    }

    if (Password === "") {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุ Password");
      setOpenMsgBox(true);
      return;
    }

    if (Token.length > 6) {
      setTitleMsgBox("");
      setBodyMsgBox("Token ต้องไม่เกิน 6 หลัก");
      setOpenMsgBox(true);
      return;
    }


    if (FName === "") {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุชื่อ");
      setOpenMsgBox(true);
      return;
    }

    if (LName === "") {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุนามสกุล");
      setOpenMsgBox(true);
      return;
    }

    if (Tel === "") {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุเบอร์โทรศัพท์ เพื่อติดต่อส่งของรางวัล");
      setOpenMsgBox(true);
      return;
    }

    if (Email === "") {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุอีเมล์ ใช้กรณีลืมรหัสผ่าน");
      setOpenMsgBox(true);
      return;
    }

    if (Addr === "") {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุที่อยู่ เพื่อจัดส่งของรางวัล");
      setOpenMsgBox(true);
      return;
    }

    if (!agree) {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดคลิก ข้าพเจ้ายอมรับข้อตกลงและเงื่อนไข");
      setOpenMsgBox(true);
      return;
    }

    const d = NewDay < 10 ? "0" + NewDay : NewDay;
    const m = NewMonth < 10 ? "0" + NewMonth : NewMonth;

    const formData = new FormData();

    formData.append("custcode", CustCode);
    formData.append("custname", CustName);
    formData.append("password", Password);
    formData.append("token", Token);
    formData.append("prefix", Prefix);
    formData.append("fname", FName);
    formData.append("lname", LName);
    formData.append("sex", Sex);
    formData.append("birthday", NewYear + "-" + m + "-" + d);
    formData.append("addr", Addr);
    formData.append("tel", Tel);
    formData.append("email", Email);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_register.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 1) {
          localStorage.setItem("bpsoft_score_membercode", CustCode);
          localStorage.setItem("bpsoft_score_memberpass", Password);

          setRegisterd(true);
          setMessageBoxTitle("ขอบคุณสำหรับการลงทะเบียน");
          setMessageBoxBody("");
          setOpen(true);
        } else {
          setMessageBoxTitle("ลงทะเบียนไม่สำเร็จ");
          setMessageBoxBody(response.data[0].message);
          setOpen(true);
        }
      })
      .catch(function (error) {
        alert(error);
      });
  }
  /*
  useEffect(() => {
    const onConfirmRefresh = function (event) {
      event.preventDefault();
    };
    window.addEventListener("beforeunload", onConfirmRefresh, {
      capture: true,
    });
  }, []);
*/
  return (
    <div>
      <Container
        maxWidth="xs"
        sx={{
          //height: 233,
          borderRadius: 2,
          backgroundColor: "#ffffff",
        }}
      >
        <br />
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            className="font-link"
            sx={{
              textAlign: "center",
              fontSize: "20pt",
            }}
          >
            <Box
              component="img"
              sx={{
                //height: 233,
                borderRadius: 2,
               // backgroundColor: "#dedede",
                width: 1,
                textAlign: "center",
                //maxHeight: { xs: 233, md: 167 },
                //maxWidth: { xs: 350, md: 250 },
              }}
              alt="The house from the offer."
              src={logo}
            />
            ลงทะเบียนสะสมแต้ม
          </Grid>

          <Grid
            item
            xs={12}
            className="font-link"
            sx={{
              color: "gray",
            }}
          >
            <CreateIcon/> โปรดลงทะเบียนสะสมแต้มเพื่อรับสิทธิแลกของรางวัล
          </Grid>

          <Grid item xs={12}>
            <TextField
              helperText="อักษรภาษาอังกฤษหรือตัวเลขไม่เกิน 10 หลัก"
              fullWidth
              label="Username"
              variant="outlined"
              value={CustCode}
              inputProps={{ maxLength: 10 }}
              onChange={(e) => {
                setCustCode(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              helperText="อักษรภาษาอังกฤษหรือตัวเลขไม่เกิน 10 หลัก"
              label="Password"
              variant="outlined"
              value={Password}
              inputProps={{ maxLength: 10 }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} className="font-link" sx={{fontSize:'10pt',color: "gray"}}>
            <TextField
              fullWidth
              helperText="ตัวเลขไม่เกิน 6 หลัก"
              type="number"
              label="รหัส Token"
              variant="outlined"
              value={Token}
              InputProps={{ inputProps: { min: 1, max: 999999 } }}
              onChange={(e) => {
                setToken(e.target.value);
              }}
            />
            รหัส Token ใช้ยืนยันแลกของรางวัล สำหรับเจ้าของร้านเท่านั้น
          </Grid>

          <Grid
            item
            xs={12}
            className="font-link"
            sx={{
              color: "gray",
              verticalAlign:"middle"
            }}
          >

            <CreateIcon/> ชื่อและที่อยู่ในการจัดส่งรางวัล

          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">คำนำหน้า</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={Prefix}
                label="คำนำหน้า"
                onChange={(e) => {
                  setPrefix(e.target.value);
                }}
              >
                <MenuItem value={"นาย"}>นาย</MenuItem>
                <MenuItem value={"นาง"}>นาง</MenuItem>
                <MenuItem value={"นางสาว"}>นางสาว</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              className="App-bottom"
              fullWidth
              label="ชื่อ"
              variant="outlined"
              value={FName}
              onChange={(e) => {
                setFName(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="นามสกุล"
              variant="outlined"
              value={LName}
              onChange={(e) => {
                setLName(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">เพศ</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={Sex}
                label="เพศ"
                onChange={(e) => {
                  setSex(e.target.value);
                }}
              >
                <MenuItem value={"M"}>ชาย</MenuItem>
                <MenuItem value={"F"}>หญิง</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">เกิดวันที่</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={NewDay}
                label="วันที่"
                onChange={(e) => {
                  setNewDay(e.target.value);
                }}
              >
                {days.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">เดือน</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={NewMonth}
                label="วันที่"
                onChange={(e) => {
                  setNewMonth(e.target.value);
                }}
              >
                {months.map((item) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">ปี พ.ศ.</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={NewYear}
                label="วันที่"
                onChange={(e) => {
                  setNewYear(e.target.value);
                }}
              >
                {years.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              type="number"
              label="โทรศัพท์"
              variant="outlined"
              value={Tel}
              onChange={(e) => {
                setTel(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="อีเมล์"
              type="email"
              variant="outlined"
              value={Email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="ชื่อร้านค้า"
              variant="outlined"
              value={CustName}
              onChange={(e) => {
                setCustName(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="ที่อยู่"
              variant="outlined"
              multiline
              rows={4}
              value={Addr}
              onChange={(e) => {
                setAddr(e.target.value);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            className="font-link"
            sx={{
              color: "gray",
            }}
          >
            MF Auto ให้ความสำคัญกับข้อมูลส่วนบุคคลของท่าน
            โปรดอ่านรายละเอียดเกี่ยวกับเงื่อนไขการใช้งานและนโยบายด้านความเป็นส่วนตัว
          </Grid>


          <Grid
            item
            xs={12}
            className="font-link"
            sx={{
              color: "gray",
            }}
          >
            <Checkbox
              checked={agree}
              onChange={() => {
                setAgree(!agree);
              }}
            />
            ข้าพเจ้ายอมรับ
            <Link
             // component="button"
              //variant="body2"
              onClick={() => {
                setOpenAgree(true);
              }}
            >
              ข้อตกลงและเงื่อนไข
            </Link>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Button fullWidth variant="contained" onClick={saveDatabase}>
              ลงทะเบียน
            </Button>
          </Grid>
        </Grid>
        <br />
        <br />
      </Container>

      <Dialog
        open={open}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{messageBoxTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {messageBoxBody}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              if (registerd) {
               window.location = "/point/?r=Y";
               //window.location = process.env.REACT_APP_URL + "/?r=Y";
                //navigate("/membermain", { replace: true });
              }
            }}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openMsgBox} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{titleMsgBox}</DialogTitle>
        <DialogContent>
          <DialogContentText>{bodyMsgBox}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenMsgBox(false);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        open={openAgree}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // className="bp-dialog"
      >
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpenAgree(false);
              }}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              นโยบายความเป็นส่วนตัว
            </Typography>
          </Toolbar>
        </AppBar>

        <Container sx={{paddingTop:'80px'}}>

          <CardMedia
            component="iframe"
            sx={{
              height: "80vh",
            }}
            src={process.env.REACT_APP_URL + "/score_agree.html"}
          />

          <br />

          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setOpenAgree(false);
            }}
          >
            ไปหน้าที่แล้ว
          </Button>
          <br />
        </Container>
      </Dialog>
    </div>
  );
}
