import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
  Avatar,
  Fab,
  Grid,
  Checkbox,
  FormControlLabel,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";

import { useEffect, useState } from "react";
import axios from "axios";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./App.css";

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 50,
    cellClassName: "DataGrid-link",
  },

  {
    field: "groupname",
    headerName: "กลุ่ม",
    width: 150,
  },

  {
    field: "topic",
    headerName: "หัวเรื่อง",
    width: 500,
    //editable: true,
  },

  {
    field: "pic",
    headerName: "รูป",
    width: 60,
    //editable: true,
    renderCell: (params) => (
      <Avatar src={process.env.REACT_APP_URL + "/upload/" + params.value} />
    ), // renderCell will render the component
  },
  {
    field: "amt",
    headerName: "มูลค่า",
    type: "number",

    renderCell: (params) => Number(params.value).toLocaleString(),
  },
  {
    field: "score",
    headerName: "คะแนน",
    type: "number",
    renderCell: (params) => Number(params.value).toLocaleString(),
  },

  {
    field: "highlight",
    headerName: "HighLight",

    renderCell: (params) =>
      params.value === "Y" ? (
        <img src={process.env.REACT_APP_URL + "/images/ok.gif"} alt="" />
      ) : (
        ""
      ),
  },

  {
    field: "isstatus",
    headerName: "เปิดให้แลก",

    renderCell: (params) =>
      params.value === "Y" ? (
        <img src={process.env.REACT_APP_URL + "/images/ok.gif"} alt="" />
      ) : (
        ""
      ),
  },
];

export default function UserReward() {
  const [id, setId] = React.useState(0);
  const [groupid, setGroupid] = React.useState(0);
  const [topic, setTopic] = React.useState("");
  const [descriptions, setDescriptions] = React.useState("");
  const [amt, setAmt] = React.useState(0);
  const [score, setScore] = React.useState(0);
  const [highLight, setHighLight] = React.useState(false);
  const [isstatus, setIsstatus] = React.useState(false);
  const [file, setFile] = useState(null);

  const [ItemCode, setItemCode] = React.useState("");
  //const [ItemName, setItemName] = React.useState("");

  const [ItemPic, setItemPic] = React.useState(null);
  const [Data, setData] = React.useState([]);
  const [DataGroup, setDataGroup] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);

  const [reload, setReload] = React.useState(0);
  const [imageUrl, setImageUrl] = useState(null);

  const [openDelete, setOpenDelete] = React.useState(false);

  const [openInfoBox, setOpenInfoBox] = React.useState(false);
  const [titleInfoBox, setTitleInfoBox] = React.useState("");
  const [bodyInfoBox, setBodyInfoBox] = React.useState("");

  const modules = {
    clipboard: {
      matchVisual: false,
    },
    // other modules...
  };

  async function saveDatabase() {
    setLoading(true);

    const formData = new FormData();

    formData.append("id", id);
    formData.append("groupid", groupid);
    formData.append("topic", topic);
    formData.append("descriptions", descriptions);
    formData.append("amt", amt);
    formData.append("score", score);
    formData.append("highlight", highLight ? "Y" : "N");
    formData.append("isstatus", isstatus ? "Y" : "N");
    formData.append("itempic", file);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_ins_reward.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 1) {
          // GetItem();
          setOpenForm(false);
          setReload(reload + 1);
        } else {
          alert(response.data[0].message);
          //console.log(response.data[0]);
        }
        setLoading(false);
      })
      .catch(function (error) {
        alert(error);
        //console.log(error);
        setLoading(false);
      });
  }

  async function delDatabase() {
    setLoading(true);

    const formData = new FormData();

    formData.append("id", id);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_del_reward.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        //console.log(response);

        if (response.data[0].statuscode === 1) {
          // GetItem();

          setOpenForm(false);
          setOpenDelete(false);
          setReload(reload + 1);
        } else {
          setTitleInfoBox("ลบไม่ได้");
          setBodyInfoBox(response.data[0].message);
          setOpenDelete(false);
          setOpenInfoBox(true);
        }
        setLoading(false);
      })
      .catch(function (error) {
        alert(error);
        setLoading(false);
      });
  }

  async function UpdateItemPic() {
    setLoading(true);

    const formData = new FormData();
    formData.append("itempic", file);
    formData.append("itemcode", ItemCode);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_upd_itempic.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 1) {
          GetItem();
        } else {
          alert(response.data[0].message);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  async function UpdateItem() {
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_upd_item.php",
      data: { itemcode: selectedRows, score: score },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 1) {
          GetItem();
        } else {
          alert(response.data[0].message);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  async function GetItem() {
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_reward.php",
      //data: { itemcode: ItemCode, itemname: "" },
    })
      .then(function (response) {
        //console.log(response.data);
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetGroup() {
    //setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_group.php",
    })
      .then(function (response) {
        setDataGroup(response.data);
        // setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    GetItem();
    GetGroup();
  }, [reload]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    setOpen(false);
    UpdateItem();
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
  };
  const handleOkUpload = () => {
    setOpenUpload(false);
    UpdateItemPic();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
      setFile(file);
      //console.log(reader.result)
    };
    reader.readAsDataURL(file);
  };

  function extractContent(s) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            fileName: "ของรางวัล",
           // delimiter: ";",
            utf8WithBom: true,
          }}
          printOptions={{
            fileName:"ของรางวัล",
            hideFooter: true,
            hideToolbar: true,
          }}          
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          backgroundColor: "#ffffff",
          borderRadius: 2,
        }}
      >
        <DataGrid
          rows={Data}
          columns={columns}
          slots={{
            toolbar: CustomToolbar,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          //checkboxSelection
          disableRowSelectionOnClick
          loading={loading}
          onRowSelectionModelChange={(data) => {
            setSelectedRows(data);
            //console.log(data);
          }}
          onCellClick={(data) => {
            if (data.field === "id") {
              setItemPic(data.row.pic);
              setId(data.row.id);
              setGroupid(data.row.groupid);
              setTopic(data.row.topic);
              setDescriptions(data.row.descriptions);
              setAmt(data.row.amt);
              setScore(data.row.score);
              setHighLight(data.row.highlight === "Y" ? true : false);
              setIsstatus(data.row.isstatus === "Y" ? true : false);
              setImageUrl(null);

              //alert(data.row.descriptions);

              setOpenForm(true);
            }
          }}
        />

        <Alert severity="info">คลิกที่รหัสเมื่อต้องการแก้ไข</Alert>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Point แบตเตอรี่</DialogTitle>
        <DialogContent>
          <DialogContentText>
            สำหรับ {selectedRows.length} รายการที่เลือก
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="คะแนน"
            type="number"
            fullWidth
            onChange={(e) => {
              setScore(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUpload}
        onClose={handleCloseUpload}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          รูปของรางวัล {ItemCode}
        </DialogTitle>
        <DialogContent>
          <Box
            component="img"
            width={1}
            src={process.env.REACT_APP_URL + "/upload/" + ItemPic}
            alt=""
          />

          <br />
          <br />
          <label htmlFor="upload-image">
            <Button variant="contained" component="span">
              เปลี่ยนรูป
            </Button>

            <input
              id="upload-image"
              hidden
              accept="image/*"
              type="file"
              onChange={handleFileUpload}
            />
          </label>
          <br />
          {imageUrl && <img src={imageUrl} alt="Uploaded Image" width="200" />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpload} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOkUpload} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openForm}
        onClose={handleCloseUpload}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {id === 0 ? "เพิ่ม" : "แก้ไข"}ของรางวัล
        </DialogTitle>
        <DialogContent>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  กลุ่มของรางวัล
                </InputLabel>
                <Select
                  // labelId="demo-simple-select-label"
                  // id="demo-simple-select"
                  value={groupid}
                  label="กลุ่มของรางวัล"
                  onChange={(e) => {
                    setGroupid(e.target.value);
                  }}
                >
                  {DataGroup.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.groupname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="หัวเรื่อง"
                variant="outlined"
                value={topic}
                onChange={(e) => {
                  setTopic(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <ReactQuill
                theme="snow"
                value={descriptions}
                modules={modules}
                // onChange={setDescriptions}
                onChange={(value) => {
                  if (extractContent(value).length !== 0) {
                    setDescriptions(value);
                  } else {
                    setDescriptions("");
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="มูลค่า"
                variant="outlined"
                value={amt}
                onChange={(e) => {
                  setAmt(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="คะแนน"
                variant="outlined"
                value={score}
                onChange={(e) => {
                  setScore(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox checked={highLight} />}
                label="HighLight"
                onChange={(e) => {
                  setHighLight(e.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox checked={isstatus} />}
                label="เปิดให้แลกได้"
                onChange={(e) => {
                  setIsstatus(e.target.checked);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              {ItemPic && (
                <Box
                  component="img"
                  width={1}
                  src={process.env.REACT_APP_URL + "/upload/" + ItemPic}
                  alt=""
                />
              )}
            </Grid>

            <Grid item xs={6}>
              {imageUrl && (
                <Box component="img" width={1} src={imageUrl} alt="" />
              )}
            </Grid>

            <Grid item xs={6}>
              <label htmlFor="upload-image">
                <Button variant="contained" component="span">
                  เลือกรูป
                </Button>

                <input
                  id="upload-image"
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleFileUpload}
                />
              </label>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={id === 0}
            onClick={() => setOpenDelete(true)}
            color="primary"
          >
            DELETE
          </Button>

          <Button onClick={() => setOpenForm(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={saveDatabase} color="primary">
            SAVE
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        //onClose={}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">ยืนยัน</DialogTitle>
        <DialogContent>
          <DialogContentText>ลบของรางวัล</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDelete(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={delDatabase} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openInfoBox} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{titleInfoBox}</DialogTitle>
        <DialogContent>
          <DialogContentText>{bodyInfoBox}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenInfoBox(false);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Fab
        onClick={() => {
          setOpenForm(true);
          setId(0);
          setDescriptions("");
          setAmt(0);
          setScore(0);
          setItemPic("");
          setFile(null);
          setImageUrl(null);
        }}
        color="primary"
        aria-label="add"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
      >
        <AddIcon />
      </Fab>
    </>
  );
}
