import * as React from "react";
import { useEffect } from "react";
import {
  Grid,
  Container,
  Box,

  Typography,

  Card,
  CardActionArea,
  CardContent
} from "@mui/material";

import "./UserLogin.css";
import "./MemberMain.css";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import MemberHead from "./MemberHead";

//  use point screen

export default function MemberSms() {
  const navigate = useNavigate();

 // const [Reload, setReload] = React.useState(0);

  //const [openMsgBox, setOpenMsgBox] = React.useState(false);

  //const [open, setOpen] = React.useState(false);
  const [isOk, setIsOk] = React.useState(false);
  const [itemData, setItemData] = React.useState([]);
  //const [score, setScore] = React.useState(0);
  const [CustPic, setCustPic] = React.useState(null);
  //const [CustName, setCustName] = React.useState("");
  //const [FName, setFName] = React.useState("");
  //const [LName, setLName] = React.useState("");

  // current selected reward


  const u = localStorage.getItem("bpsoft_score_membercode");
  const p = localStorage.getItem("bpsoft_score_memberpass");


  async function GetItem() {
    //setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_sms.php",
      data: { custcode: u },
    })
      .then(function (response) {

        //console.log(response);
        setItemData(response.data);
        //setLoading(false);
      })
      .catch(function (error) {
        alert(error);
        //console.log(error);
      });
  }

  async function CheckUser() {
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_member.php",
      data: { custcode: u, password: p },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 0) {
          
          navigate("/memberlogin", { replace: true });
        } else {
          setCustPic(
            response.data[0].custpic === ""
              ? "user.png"
              : response.data[0].custpic
          );

          //setCustName(response.data[0].custname);
          //setFName(response.data[0].fname);
          //setLName(response.data[0].lname);
          //setScore(response.data[0].score);

          GetItem();
          setIsOk(true);
        }
      })
      .catch(function (error) {
        //console.log(error);
        alert(error);
      });
  }


  useEffect(() => {
    CheckUser();

    
      const onConfirmRefresh = function (event) {
        event.preventDefault();
       // return (event.returnValue = "Are you sure you want to leave the page?");
      };
      window.addEventListener("beforeunload", onConfirmRefresh, {
        capture: true,
      });
    

  }, []);

  return (
    <div>
      <Container
        maxWidth="xs"
        sx={{
          visibility: isOk ? "visible" : "hidden",
          //backgroundColor: "#dedede",
        }}
      >
        <MemberHead/>

        <Grid container spacing={0}>
          <Grid item xs={12} sx={{ textAlign: "center" }} className="font-link">

            <Box
              sx={{
                color: "blue",
                width: 1,
                backgroundColor: "#e6e6e6",
                textAlign: "center",
                fontSize: "20pt",
              }}
            >
              แจ้งเตือน
            </Box>
          </Grid>
        </Grid>
        <br />
        {itemData.map((item) => (
          
          <div style={{ padding: 3 }}>
            <Card key={item.id} sx={{ paddingBlock: "20" }}>
              <CardActionArea>
 
                <CardContent>
                  <Typography component="div">
                    {item.datecreate}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.descriptions} 
                             </Typography>
                </CardContent>
              </CardActionArea>

            </Card>
            
          </div>
        ))}


        <br />
        <br />
        <br />
      </Container>


      
    </div>
  );
}
