import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Grid,
  Container,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";

import "./UserLogin.css";
import logo from "./user.png";
import axios from "axios";

export default function UserLogin() {
  const navigate = useNavigate();
  const [UserID, setUserID] = useState("");
  const [Password, setPassword] = useState("");
  const [open, setOpen] = React.useState(false);

  async function GetUser() {
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_user.php",
      data: { userid: UserID, password: Password },
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data[0].statuscode === 0) {
          setOpen(true);
        } else {
          //window.location = "/usermain";
          navigate("/usermain");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Container maxWidth="xs" className="UserLogin-body">
        <Grid container spacing={2}>
          <Grid item xs={12} className="font-link" sx={{ textAlign: "center" }}>
            <Box
              component="img"
              sx={{
                borderRadius: 2,
                width: 150,
                textAlign: "center",
              }}
              alt="The house from the offer."
              src={logo}
            />

            <Box
              sx={{
                color: "blue",
                width: 1,
                textAlign: "center",
                fontSize: "20pt",
              }}
            >
              ระบบสะสมแต้ม Back Office
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="รหัสผู้ใช้"
              variant="outlined"
              value={UserID}
              onChange={(e) => {
                setUserID(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              type="password"
              label="รหัสผ่าน"
              variant="outlined"
              value={Password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Button fullWidth variant="contained" onClick={GetUser}>
              เข้าระบบ
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"เข้าระบบไม่ได้"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            รหัสผู้ใช้ หรือ รหัสผ่าน ไม่ถูกต้อง
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            ตกลง
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
