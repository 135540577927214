import * as React from "react";
import { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
//import { Pagination } from "swiper/modules";

import {
  Grid,
  Container,
  Box,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Avatar,
  CardMedia,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import "./UserLogin.css";
import "./MemberMain.css";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import MemberHead from "./MemberHead";

export default function MemberUse() {
  const navigate = useNavigate();

  const [Reload, setReload] = React.useState(0);

  const [openMsgBox, setOpenMsgBox] = React.useState(false);
  const [MsgBox, setMsgBox] = React.useState("");
  const [MsgBoxHead, setMsgBoxHead] = React.useState("");

  const [openInfoBox, setOpenInfoBox] = React.useState(false);
  const [titleInfoBox, setTitleInfoBox] = React.useState("");
  const [bodyInfoBox, setBodyInfoBox] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [isOk, setIsOk] = React.useState(true);

  const [itemData, setItemData] = React.useState([]);
  const [proData, setProData] = React.useState([]);
  const [dataGroup, setDataGroup] = React.useState([]);

  const [itemDataAll, setItemDataAll] = React.useState([]);
  const [proDataAll, setProDataAll] = React.useState([]);

  const [score, setScore] = React.useState(0);
  const [CustPic, setCustPic] = React.useState(null);

  const [FName, setFName] = React.useState("");
  const [LName, setLName] = React.useState("");
  const [enddate, setEnddate] = React.useState("");
  const [token, setToken] = React.useState("");
  const [memberToken, setMemberToken] = React.useState("");

  // current selected reward
  const [selectRewardId, setSelectRewardId] = useState(0);
  const [selectCustCode, setSelectCustCode] = useState("");
  const [selectPic, setSelectPic] = useState("");
  const [selectScore, setSelectScore] = useState(0);
  const [selectAmt, setSelectAmt] = useState(0);

  const [selectDescriptions, setSelectDescriptons] = useState("");
  const [selectTopic, setSelectTopic] = useState("");

  const [openAllPromotion, setOpenAllPromotion] = React.useState(false);
  const [openDetailPromotion, setOpenDetailPromotion] = React.useState(false);
  const [openAllReward, setOpenAllReward] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);

  const [groupid, setGroupid] = useState(999);

  const u = localStorage.getItem("bpsoft_score_membercode");
  const p = localStorage.getItem("bpsoft_score_memberpass");

  async function Send_line() {
    const formData = new FormData();

    formData.append("custcode", selectCustCode);
    formData.append("rewardid", selectRewardId);
    formData.append("topic", selectTopic);
    formData.append("descriptions", selectDescriptions);
    formData.append("pic", selectPic);
    formData.append("amt", selectAmt);
    formData.append("score", selectScore);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_send_line.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 1) {
          //setReload(Reload + 1);
        } else {
        }
      })
      .catch(function (error) {
        alert(error);
      });
  }

  async function SaveRedeem() {
    //  console.log(u);

    if (token !== memberToken) {
      setTitleInfoBox("");
      setBodyInfoBox("รหัส Token ไม่ถูกต้อง");
      setOpenInfoBox(true);
      return;
    }

    const formData = new FormData();

    setOpenMsgBox(false);

    formData.append("custcode", selectCustCode);
    formData.append("rewardid", selectRewardId);
    formData.append("topic", selectTopic);
    formData.append("descriptions", selectDescriptions);
    formData.append("pic", selectPic);
    formData.append("amt", selectAmt);
    formData.append("score", selectScore);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_redeem.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        // console.log(response);
        setOpen(false);
        if (response.data[0].statuscode === 1) {
          Send_line();

          setReload(Reload + 1);
        } else {
        }
      })
      .catch(function (error) {
        alert(error);
      });
  }

  async function GetItem(qty, g) {
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_reward.php",
      data: { isstatus: "Y", limit: qty, groupid: g },
    })
      .then(function (response) {
        if (qty === 2) {
          setItemDataAll(response.data);
        } else {
          setItemData(response.data);
          //setSlideQty(2);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetGroup() {
    //setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_group.php",
    })
      .then(function (response) {
        response.data.push({ id: 999, groupname: "ทั้งหมด" });
        setDataGroup(response.data);

        // setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetPro(qty) {
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_pro.php",
      data: { itemcode: "", itemname: "", limit: qty },
    })
      .then(function (response) {
        if (qty === 0) {
          setProDataAll(response.data);
        } else {
          setProData(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function CheckUser() {
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_member.php",
      data: { custcode: u, password: p },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 0) {
          navigate("/memberlogin", { replace: true });
        } else {
          setCustPic(
            response.data[0].custpic === ""
              ? "user.png"
              : response.data[0].custpic
          );

          setSelectCustCode(u);
          // setCustName(response.data[0].custname);
          setFName(response.data[0].fname);
          setLName(response.data[0].lname);
          setScore(response.data[0].score);
          setEnddate(response.data[0].enddate);
          setMemberToken(response.data[0].token);

          GetItem(1, 0); // highlight
          GetPro(3);

          GetItem(2, 999); // all

          GetPro(0);

          // setIsOk(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleClose = () => {
    setOpen(false);
    setClicked(false);
  };

  const handleOpenMsgBox = () => {
    setOpenMsgBox(true);
  };
  const handleCloseMsgBox = () => {
    setOpenMsgBox(false);
    setClicked(false);
  };

  const clickOpenAllPromotion = () => {
    setOpenAllPromotion(true);
  };

  useEffect(() => {
    CheckUser();
    GetGroup();
    setClicked(false);
    // setShowPin(false);

    if (Reload === 0) {
      const onConfirmRefresh = function (event) {
        event.preventDefault();
        // return (event.returnValue = "Are you sure you want to leave the page?");
      };
      window.addEventListener("beforeunload", onConfirmRefresh, {
        capture: true,
      });
    }
  }, [Reload]);

  return (
    <div>
      <Container maxWidth="xs">
        <MemberHead />
      </Container>
      <Container
        maxWidth="xs"
        sx={{
          visibility: isOk ? "visible" : "hidden",
        }}
      >
        <Grid
          container
          rowSpacing={0}
          sx={{
            textAlign: "center",
            border: 1,
            borderColor: "grey.500",
            // borderRadius: "5px",
          }}
        >
          <Grid
            className="font-link"
            item
            xs={6}
            sx={{
              textAlign: "center",
            }}
          >
            <Avatar
              sx={{
                width: 150,
                height: 150,
                margin: "auto",
              }}
              alt={FName}
              src={process.env.REACT_APP_URL + "/upload/" + CustPic}
            />

            <Box sx={{ fontSize: "12pt", color: "blue" }}>
              {FName} {LName}
            </Box>
          </Grid>

          <Grid
            className="font-link"
            item
            xs={6}
            sx={{
              textAlign: "center",
              borderRadius: "20px",
              verticalAlign: "middle",
            }}
          >
            <br />

            <Box
              sx={{
                fontSize: "40pt",
                color: "blue",
                textShadow: "2px 2px 4px #ffffff",
              }}
            >
              {score}
            </Box>
            <Box sx={{ fontSize: "10pt", color: "blue" }}>คะแนน</Box>
            <br />
            <Box sx={{ fontSize: "8pt", color: "#808080" }}>
              หมดอายุวันที่ {enddate}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ paddingTop: 1 }}>
          <Grid className="font-link" item xs={8}>
            <Box
              sx={{
                color: "blue",
                borderRadius: "20px",
                // backgroundColor: "#ffffff",
                textAlign: "left",

                fontSize: "16pt",
              }}
            >
              ของรางวัล
            </Box>
          </Grid>
          <Grid
            className="font-link"
            item
            xs={4}
            sx={{
              textAlign: "left",
            }}
          >
            <Button
              fullWidth
              variant="text"
              onClick={() => {
                setOpenAllReward(true);
              }}
            >
              ทั้งหมด
            </Button>
          </Grid>
        </Grid>

        {/*=========================== slider ของรางวัล หน้าแรก =========================*/}

        <Swiper
          slidesPerView={2}
          spaceBetween={10}
          className="mySwiper"
          modules={[Navigation]}
          navigation={true}
          pagination={{ clickable: true }}
        >
          {itemData.map((item) => (
            <SwiperSlide>
              <Box
                key={item.id}
                onClick={() => {
                  setSelectRewardId(item.id);
                  setSelectScore(item.score);
                  setSelectAmt(item.amt);
                  setSelectTopic(item.topic);
                  setSelectDescriptons(item.descriptions);
                  setSelectPic(item.pic);
                  setOpen(true);
                  // setMsgBox("ยืนยันแลกคะแนน");
                  setMsgBoxHead("ใช้ " + item.score + " คะแนน แลกของรางวัล");
                }}
                sx={{
                  // height: "200px",
                  border: 1,
                  borderColor: "grey.500",
                  // borderRadius: "5px",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                  padding: "2px",
                  backgroundColor: "#ffffff",
                }}
              >
                <CardMedia
                  component="img"
                  sx={
                    {
                      // height: "150px",
                    }
                  }
                  alt={item.topic}
                  src={
                    item.pic === ""
                      ? process.env.REACT_APP_URL + "/upload/giftbox.png"
                      : process.env.REACT_APP_URL + "/upload/" + item.pic
                  }
                  className={
                    Number(item.score) <= Number(score)
                      ? "img-enabled"
                      : "img-disabled"
                  }
                />

                <Typography
                  nowrap={true}
                  sx={{ color: "#808080", fontSize: "9pt" }}
                >
                  {item.topic}
                </Typography>

                <Grid container spacing={0}>
                  <Grid item xs={5} sx={{ textAlign: "left" }}>
                    <Typography
                      sx={{ color: "#FF0000", fontSize: "14pt" }}
                      className="font-link"
                    >
                      {Number(item.score).toLocaleString()}
                    </Typography>
                    <Typography
                      nowrap={true}
                      sx={{ color: "#808080", fontSize: "10pt", marginTop: -1 }}
                    >
                      คะแนน
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sx={{ textAlign: "center" }}>
                    <Button
                      size="small"
                      disabled={
                        Number(item.score) <= Number(score) ? false : true
                      }
                      variant="outlined"
                      color="error"
                    >
                      แลกรางวัล
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>

        {/*=========================== slider =========================*/}

        <Grid container spacing={1} sx={{ paddingTop: 1 }}>
          <Grid className="font-link" item xs={8}>
            <Box
              sx={{
                color: "blue",
                borderRadius: "20px",
                //backgroundColor: "#ffffff",
                textAlign: "left",
                fontSize: "16pt",
              }}
            >
              ข่าวสารและโปรโมชั่น
            </Box>
          </Grid>
          <Grid
            className="font-link"
            item
            xs={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button fullWidth variant="text" onClick={clickOpenAllPromotion}>
              ทั้งหมด
            </Button>
          </Grid>
        </Grid>
        {/*=========================== แสดง promotion ในหน้าแรก =========================*/}

        <Swiper
          slidesPerView={2}
          spaceBetween={10}
          pagination={{ clickable: true, dynamicBullets: true }}
          className="mySwiper"
          navigation={true}
          modules={[Navigation]}
          // watchSlidesVisibility={true}
          //navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}
        >
          {proData.map((item) => (
            <SwiperSlide>
              <Box
                key={item.id}
                onClick={() => {
                  setSelectTopic(item.topic);
                  setSelectDescriptons(item.descriptions);
                  setSelectPic(item.pic_title);
                  setOpenDetailPromotion(true);
                }}
                sx={{
                  //height: "265px",
                  border: 1,
                  borderColor: "grey.500",
                  //borderRadius: "5px",
                  overflow: "hidden",
                  overflowWrap: "break-word",
                  padding: "2px",
                  backgroundColor: "#ffffff",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    // width: "200px",
                    border: 0,
                    borderColor: "grey.300",
                    // height: "150px",
                    // textAlign: "center",
                    //margin: "auto",
                  }}
                  alt={item.topic}
                  src={
                    item.pic_title === ""
                      ? process.env.REACT_APP_URL + "/upload/discount.png"
                      : process.env.REACT_APP_URL + "/upload/" + item.pic_title
                  }
                />

                <Box sx={{ color: "#808080", fontSize: "9pt" }}>
                  {item.topic}
                </Box>

                <Grid container spacing={0}>
                  <Grid item xs={5} sx={{ textAlign: "left" }}></Grid>
                  <Grid item xs={7} sx={{ textAlign: "center" }}>
                    <Button
                      size="small"
                      variant="text"
                      //color="error"
                    >
                      รายละเอียด
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>

        <br />
        <br />
        <br />
      </Container>

      {/* =========================== รายละเอียด promotion  ====================================== */}
      <Dialog
        fullScreen
        open={openDetailPromotion}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpenDetailPromotion(false);
              }}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              ข่าวสารและโปรโมชั่น
            </Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth="xs" sx={{ paddingTop: "80px" }}>
          <Grid container rowSpacing={4}>
            <Grid item xs={12}>
              <Box
                component="img"
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  width: 1,
                }}
                src={
                  selectPic === ""
                    ? process.env.REACT_APP_URL + "/upload/discount.png"
                    : process.env.REACT_APP_URL + "/upload/" + selectPic
                }
              />

              <Box
                className="font-link"
                sx={{
                  textAlign: "center",
                  color: "blue",
                  fontSize: "14pt",
                }}
              >
                {selectTopic}
              </Box>

              <Box
                sx={{
                  width: 1,
                  fontSize: "10pt",
                }}
              >
                <div
                  //style={{ lineHeight: 1.6 }}
                  dangerouslySetInnerHTML={{ __html: selectDescriptions }}
                />
              </Box>
            </Grid>
          </Grid>
          <br />
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setOpenDetailPromotion(false);
            }}
          >
            ไปหน้าที่แล้ว
          </Button>
          <br />
          <br />
          <br />
          <br />
        </Container>
      </Dialog>

      <Dialog
        //===================================== message box =============================
        open={openMsgBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{MsgBoxHead}</DialogTitle>
        <DialogContent>
          <br />
          <TextField
            fullWidth
            autoFocus={true}
            type="number"
            label="ป้อนรหัส Token"
            variant="outlined"
            value={token}
            onChange={(e) => {
              setToken(e.target.value);
            }}
          />
          <box>{MsgBox}</box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMsgBox} color="primary">
            Cancel
          </Button>
          <Button onClick={SaveRedeem} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        //============================ open all promotion =========================
        fullScreen
        open={openAllPromotion}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpenAllPromotion(false);
              }}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              ข่าวสารและโปรโมชั่น
            </Typography>
          </Toolbar>
        </AppBar>

        <Container maxWidth="xs" sx={{ paddingTop: "60px" }}>
          <br />
          <Grid container spacing={1}>
            {proDataAll.map((item) => (
              <Grid
                item
                //className="font-link"
                xs={6}
              >
                <Box
                  key={item.id}
                  onClick={() => {
                    setSelectTopic(item.topic);
                    setSelectDescriptons(item.descriptions);
                    setSelectPic(item.pic_title);
                    setOpenDetailPromotion(true);
                  }}
                  sx={{
                    border: 1,
                    borderColor: "grey.500",
                    overflow: "hidden",
                    overflowWrap: "break-word",
                    padding: "2px",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={
                      {
                        //paddingTop: '100%',
                        //objectFit: 'cover',
                        // width: "200px",
                        // height: "150px",
                        // textAlign: "center",
                        //margin: "auto",
                      }
                    }
                    alt={item.descriptions}
                    src={
                      item.pic_title === ""
                        ? process.env.REACT_APP_URL + "/upload/giftbox.png"
                        : process.env.REACT_APP_URL +
                          "/upload/" +
                          item.pic_title
                    }
                  />

                  <Box sx={{ color: "#808080", fontSize: "9pt" }}>
                    {item.topic}
                  </Box>

                  <Grid container spacing={0}>
                    <Grid item xs={5} sx={{ textAlign: "left" }}></Grid>
                    <Grid item xs={7} sx={{ textAlign: "center" }}>
                      <Button
                        size="small"
                        variant="text"
                        //color="error"
                      >
                        รายละเอียด
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
          <br />
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setOpenAllPromotion(false);
            }}
          >
            ไปหน้าที่แล้ว
          </Button>
          <br />
          <br />
          <br />
          <br />
        </Container>
      </Dialog>

      <Dialog
        //============================ open all reward =========================
        fullScreen
        open={openAllReward}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpenAllReward(false);
              }}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              ของรางวัล
            </Typography>
          </Toolbar>
        </AppBar>

        <Container maxWidth="xs" sx={{ paddingTop: "60px" }}>
          <br />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              กลุ่มของรางวัล
            </InputLabel>
            <Select
              // labelId="demo-simple-select-label"
              // id="demo-simple-select"
              value={groupid}
              label="กลุ่มของรางวัล"
              onChange={(e) => {
                setGroupid(e.target.value);
                GetItem(2, e.target.value); // all
              }}
            >
              {dataGroup.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.groupname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <br />
          <Grid container spacing={1}>
            {itemDataAll.map((item) => (
              <Grid
                item
                // className="font-link"
                xs={6}
              >
                <Box
                  key={item.id}
                  onClick={() => {
                    setSelectRewardId(item.id);
                    setSelectScore(item.score);
                    setSelectAmt(item.amt);
                    setSelectTopic(item.topic);
                    setSelectDescriptons(item.descriptions);
                    setSelectPic(item.pic);
                    setOpen(true);
                    // setMsgBox("ยืนยันแลกคะแนน");
                    setMsgBoxHead("ใช้ " + item.score + " คะแนน แลกของรางวัล");
                  }}
                  sx={{
                    // height: "265px",
                    border: 1,
                    borderColor: "grey.500",
                    //borderRadius: "5px",
                    overflow: "hidden",
                    overflowWrap: "break-word",
                    padding: "2px",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={
                      {
                        //paddingTop: '100%',
                        //objectFit: 'cover',
                        // width: "200px",
                        // height: "150px",
                        // textAlign: "center",
                        //margin: "auto",
                      }
                    }
                    alt={FName}
                    src={
                      item.pic === ""
                        ? process.env.REACT_APP_URL + "/upload/giftbox.png"
                        : process.env.REACT_APP_URL + "/upload/" + item.pic
                    }
                    className={
                      Number(item.score) <= Number(score)
                        ? "img-enabled"
                        : "img-disabled"
                    }
                  />

                  <Typography
                    nowrap={true}
                    sx={{ color: "#808080", fontSize: "9pt" }}
                  >
                    {item.topic}
                  </Typography>

                  <Grid container spacing={0}>
                    <Grid item xs={5} sx={{ textAlign: "left" }}>
                      <Typography
                        sx={{ color: "#FF0000", fontSize: "14pt" }}
                        className="font-link"
                      >
                        {Number(item.score).toLocaleString()}
                      </Typography>
                      <Typography
                        nowrap={true}
                        sx={{
                          color: "#808080",
                          fontSize: "10pt",
                          marginTop: -1,
                        }}
                      >
                        คะแนน
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sx={{ textAlign: "center" }}>
                      <Button
                        disabled={
                          Number(item.score) <= Number(score) ? false : true
                        }
                        size="small"
                        variant="outlined"
                        color="error"
                      >
                        แลกรางวัล
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
          <br />
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setOpenAllReward(false);
            }}
          >
            ไปหน้าที่แล้ว
          </Button>
          <br />
          <br />
          <br />
          <br />
        </Container>
      </Dialog>

      {/* =========================== รายละเอียดของรางวัล ====================================== */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // className="bp-dialog"
      >
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              แลกของรางวัล
            </Typography>
          </Toolbar>
        </AppBar>

        <Container maxWidth="xs" sx={{ paddingTop: "60px" }}>
          <br />
          <br />
          <Grid container rowSpacing={4}>
            <Box
              className="font-link"
              sx={{
                textAlign: "center",
                width: 1,
                color: "gray",
              }}
            >
              คุณมีคะแนน {Number(score).toLocaleString()} คะแนน
            </Box>

            <Box
              component="img"
              sx={{
                border: 1,
                borderColor: "grey.500",
                width: 1,
              }}
              src={
                selectPic === ""
                  ? process.env.REACT_APP_URL + "/upload/giftbox.png"
                  : process.env.REACT_APP_URL + "/upload/" + selectPic
              }
            />
            <Box
              className="font-link"
              sx={{
                fontSize: "14pt",
                width: 1,
                color: "red",
                textAlign: "center",
              }}
            >
              {Number(selectScore).toLocaleString()} คะแนน
            </Box>
            <Box
              className="font-link"
              sx={{
                // border: 1,
                width: 1,
                fontSize: "12pt",
                color: "blue",
                textAlign: "center",
              }}
            >
              {selectTopic}
            </Box>
            <Box
              sx={{
                width: 1,
                fontSize: "10pt",
              }}
            >
              <div
                // style={{ lineHeight: 1.2 }}
                dangerouslySetInnerHTML={{ __html: selectDescriptions }}
              />
            </Box>

            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Button
                fullWidth
                disabled={
                  Number(score) < Number(selectScore) || clicked ? true : false
                }
                variant="contained"
                onClick={() => {
                  setClicked(true);
                  handleOpenMsgBox();
                }}
              >
                {Number(score) < Number(selectScore)
                  ? "คะแนนของคุณไม่เพียงพอ"
                  : "ยืนยันการแลกรางวัล"}
              </Button>
            </Grid>
          </Grid>
          <br />
          <Button fullWidth variant="contained" onClick={handleClose}>
            ไปหน้าที่แล้ว
          </Button>
          <br />
          <br />
          <br />
          <br />
        </Container>
      </Dialog>

      <Dialog open={openInfoBox} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{titleInfoBox}</DialogTitle>
        <DialogContent>
          <DialogContentText>{bodyInfoBox}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenInfoBox(false);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
