import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Container,
  Box,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Avatar,
} from "@mui/material";
import "./UserLogin.css";
import "./MemberMain.css";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MemberHead from "./MemberHead";

export default function MemberEdit() {
  const navigate = useNavigate();
  const [reload, setReload] = React.useState(0);

  const [openMsgBox, setOpenMsgBox] = React.useState(false);
  const [MsgBox, setMsgBox] = React.useState("");
  const [MsgBoxHead, setMsgBoxHead] = React.useState("");

  const [openInfoBox, setOpenInfoBox] = React.useState(false);
  const [titleInfoBox, setTitleInfoBox] = React.useState("");
  const [bodyInfoBox, setBodyInfoBox] = React.useState("");


  const [openTokenBox, setOpenTokenBox] = React.useState(false);
  const [titleTokenBox, setTitleTokenBox] = React.useState("");
  const [bodyTokenBox, setBodyTokenBox] = React.useState("");
  const [checkToken, setCheckToken] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [isOk, setIsOk] = React.useState(false);
  const [score, setScore] = React.useState(0);
  const [CustPic, setCustPic] = React.useState(null);
  const [CustName, setCustName] = React.useState("");
  const [Password, setPassword] = React.useState("");
  const [Token, setToken] = React.useState("");
  const [Prefix, setPrefix] = React.useState("");
  const [FName, setFName] = React.useState("");
  const [LName, setLName] = React.useState("");
  const [Sex, setSex] = React.useState("");
  const [Birthday, setBirthday] = React.useState("");
  const [Addr, setAddr] = React.useState("");
  const [Email, setEmail] = React.useState("");
  const [Tel, setTel] = React.useState("");
  const [enddate, setEnddate] = React.useState("");

  //==================== Edit Form ======================
  const [NewPassword, setNewPassword] = useState("");
  const [NewToken, setNewToken] = useState("");
  const [NewCustName, setNewCustName] = useState("");
  const [NewPrefix, setNewPrefix] = useState("");
  const [NewFName, setNewFName] = useState("");
  const [NewLName, setNewLName] = useState("");
  const [NewSex, setNewSex] = useState("");

  const [NewAddr, setNewAddr] = useState("");
  const [NewTel, setNewTel] = useState("");
  const [NewEmail, setNewEmail] = useState("");

  const [NewDay, setNewDay] = useState(1);
  const [NewMonth, setNewMonth] = useState(1);
  const [NewYear, setNewYear] = useState(2500);
  // ====================================================

  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [memberToken, setMemberToken] = React.useState("");

  // const [reload, setReload] = React.useState(0);
  // const [loading, setLoading] = React.useState(false);

  const u = localStorage.getItem("bpsoft_score_membercode");
  const p = localStorage.getItem("bpsoft_score_memberpass");


  let i = 0;
  let days = [];
  for (i = 1; i <= 31; i++) {
    days.push(i);
  }

  let years = [];
  for (i = 2500; i <= 2550; i++) {
    years.push(i);
  }
  let months = [
    { id: 1, name: "มกราคม" },
    { id: 2, name: "กุมภาพันธ์" },
    { id: 3, name: "มีนาคม" },
    { id: 4, name: "เมษายน" },
    { id: 5, name: "พฤษภาคม" },
    { id: 6, name: "มิถุนายน" },
    { id: 7, name: "กรกฎาคม" },
    { id: 8, name: "สิงหาคม" },
    { id: 9, name: "กันยายน" },
    { id: 10, name: "ตุลาคม" },
    { id: 11, name: "พฤศจิกายน" },
    { id: 12, name: "ธันวาคม" },
  ];

  async function UpdateMemberPic() {
    // setLoading(true);

    // event.preventDefault();
    const formData = new FormData();
    formData.append("custpic", file);
    formData.append("custcode", u);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_upd_custpic.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 1) {
          //GetItem();
          setReload(reload + 1);
        } else {
          alert(response.data[0].message);
        }
        // setLoading(false);
      })
      .catch(function (error) {
        //console.log(error);
        alert(error);
        // setLoading(false);
      });
  }

  async function UpdatMember() {
    const formData = new FormData();

    setOpenMsgBox(false);

    const d = NewDay < 10 ? "0" + NewDay : NewDay;
    const m = NewMonth < 10 ? "0" + NewMonth : NewMonth;

    formData.append("custcode", u);
    formData.append("custname", NewCustName);
    formData.append("password", NewPassword);
    formData.append("token", NewToken);
    formData.append("prefix", NewPrefix);
    formData.append("fname", NewFName);
    formData.append("lname", NewLName);
    formData.append("sex", NewSex);
    formData.append("addr", NewAddr);
    formData.append("birthday", NewYear + "-" + m + "-" + d);
    formData.append("tel", NewTel);
    formData.append("email", NewEmail);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_upd_member.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data[0].statuscode === 1) {
          setOpen(false);
          //CheckUser();
          setReload(reload + 1);
        } else {
        }
      })
      .catch(function (error) {
        alert(error);
      });
  }

  async function CheckUser() {
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_member.php",
      data: { custcode: u, password: p },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 0) {
          navigate("/memberlogin", { replace: true });
        } else {
          setCustPic(
            response.data[0].custpic === ""
              ? "user.png"
              : response.data[0].custpic
          );
          setCustName(response.data[0].custname);
          setPassword(response.data[0].password);
          setToken(response.data[0].token);
          setPrefix(response.data[0].prefix);
          setFName(response.data[0].fname);
          setLName(response.data[0].lname);
          setSex(response.data[0].sex);
          // setBirthday(response.data[0].birthday);
          setAddr(response.data[0].addr);
          setTel(response.data[0].tel);
          setEmail(response.data[0].email);
          setScore(response.data[0].score);
          setEnddate(response.data[0].enddate);
          setMemberToken(response.data[0].token);

          setNewDay(Number(response.data[0].birthday.substr(8, 2)));
          setNewMonth(Number(response.data[0].birthday.substr(5, 2)));
          setNewYear(Number(response.data[0].birthday.substr(0, 4)));

          setBirthday(
            response.data[0].birthday.substr(8, 2) +
              "/" +
              response.data[0].birthday.substr(5, 2) +
              "/" +
              response.data[0].birthday.substr(0, 4)
          );

          setIsOk(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleOpen = () => {
    setNewCustName(CustName);
    setNewPassword(Password);
    setNewToken(Token);
    setNewPrefix(Prefix);
    setNewFName(FName);
    setNewLName(LName);
    setNewAddr(Addr);
    setNewSex(Sex);
    setNewTel(Tel);
    setNewEmail(Email);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenMsgBox = () => {
    setOpenMsgBox(true);
  };

  const handleCloseMsgBox = () => {
    setOpenMsgBox(false);
  };

  const handleOkMsgBox = () => {
    //setOpenMsgBox(false);

    if (MsgBoxHead === "แก้ไขข้อมูลส่วนตัว") {
      UpdatMember();
    } else {
      localStorage.removeItem("bpsoft_score_membercode");
      localStorage.removeItem("bpsoft_score_memberpass");

      navigate("/memberlogin", { replace: true });
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
      setFile(file);
      //console.log(reader.result)
    };
    reader.readAsDataURL(file);
  };
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };
  const handleOkUpload = () => {
    setOpenUpload(false);
    UpdateMemberPic();
  };

  useEffect(() => {
    CheckUser();

    

    if (reload === 0) {
      const onConfirmRefresh = function (event) {
        event.preventDefault();
        // return (event.returnValue = "Are you sure you want to leave the page?");
      };
      window.addEventListener("beforeunload", onConfirmRefresh, {
        capture: true,
      });
    }
  }, [reload]);

  return (
    <div>
      <Container maxWidth="xs" sx={{ visibility: isOk ? "visible" : "hidden" }}>
        <MemberHead />
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{ textAlign: "center" }} className="font-link">
            <Box
              sx={{
                color: "blue",
                width: 1,
                backgroundColor: "#e6e6e6",
                textAlign: "center",
                fontSize: "20pt",
              }}
            >
              สมาชิก
            </Box>
          </Grid>
        </Grid>
        <br />
        <Grid
          container
          rowSpacing={0}
          sx={{
            textAlign: "center",
            // backgroundImage: `url(${logo})`,
            // backdropFilter:'10%',
            //backgroundImage: {process.env.REACT_APP_URL + "/upload/" + CustPic },
            border: 1,
            borderColor: "grey.500",
            // backgroundColor: "#ffffff",
           // borderRadius: "5px",
          }}
        >
          <Grid
            className="font-link"
            item
            xs={6}
            sx={{
              textAlign: "center",
            }}
          >
            <Avatar
              sx={{
                width: 150,
                height: 150,
                margin: "auto",
              }}
              alt={FName}
              src={process.env.REACT_APP_URL + "/upload/" + CustPic}
            />

            <Box sx={{ fontSize: "12pt", color: "blue" }}>
              {FName} {LName}
            </Box>
          </Grid>

          <Grid
            className="font-link"
            item
            xs={6}
            sx={{
              textAlign: "center",
             // borderRadius: "20px",
              verticalAlign: "middle",
            }}
          >
            <br />

            <Box
              sx={{
                fontSize: "40pt",
                color: "blue",
                textShadow: "2px 2px 4px #ffffff",
              }}
            >
              {score}
            </Box>
            <Box sx={{ fontSize: "10pt", color: "blue" }}>คะแนน</Box>
            <br />
            <Box sx={{ fontSize: "8pt", color: "#808080" }}>
              หมดอายุวันที่ {enddate}
            </Box>
          </Grid>
        </Grid>

        <br />
        <Grid
          container
          Spacing={1}
          className="font-link"
          sx={{
            border: 1,
            borderColor: "grey.500",
            //fontSize: "30pt",
            //backgroundColor: "#ffffff",
            //borderRadius: "5px",
          }}
        >
          <Grid item xs={4} sx={{ paddingLeft: 1 }}>
            รหัสผู้ใช้
          </Grid>
          <Grid item xs={8}>
            {u}
          </Grid>
        </Grid>
        <br />
        <Grid
          container
          Spacing={1}
          className="font-link"
          sx={{
            border: 1,
            borderColor: "grey.500",
            //fontSize: "30pt",
            //backgroundColor: "#ffffff",
           // borderRadius: "5px",
          }}
        >
          <Grid item xs={4} sx={{ paddingLeft: 1 }}>
            คำนำหน้า
          </Grid>
          <Grid item xs={8}>
            {Prefix}
          </Grid>

          <Grid item xs={4} sx={{ paddingLeft: 1 }}>
            ชื่อผู้ใช้
          </Grid>
          <Grid item xs={8}>
            {FName} {LName}
          </Grid>

          <Grid item xs={4} sx={{ paddingLeft: 1 }}>
            เพศ
          </Grid>

          <Grid item xs={8}>
            {Sex === "M" ? "ชาย" : "หญิง"}
          </Grid>

          <Grid item xs={4} sx={{ paddingLeft: 1 }}>
            วันเกิด
          </Grid>
          <Grid item xs={8}>
            {Birthday}
          </Grid>

          <Grid item xs={4} sx={{ paddingLeft: 1 }}>
            โทรศัพท์
          </Grid>
          <Grid item xs={8}>
            {Tel}
          </Grid>

          <Grid item xs={4} sx={{ paddingLeft: 1 }}>
            อีเมล์
          </Grid>
          <Grid item xs={8}>
            {Email}
          </Grid>
        </Grid>
        <br />
        <Grid
          container
          Spacing={1}
          className="font-link"
          sx={{
            border: 1,
            borderColor: "grey.500",
            //fontSize: "30pt",
            //backgroundColor: "#ffffff",
           // borderRadius: "5px",
          }}
        >
          <Grid item xs={4} sx={{ paddingLeft: 1 }}>
            ชื่อร้าน
          </Grid>
          <Grid item xs={8}>
            {CustName}
          </Grid>

          <Grid item xs={4} sx={{ paddingLeft: 1 }}>
            ที่อยู่
          </Grid>
          <Grid item xs={8}>
            {Addr}
          </Grid>
        </Grid>
        <br />
        <Grid container rowSpacing={2}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setTitleTokenBox("แก้ไขข้อมูลส่วนตัว");
                setOpenTokenBox(true);
              }}
            >
              แก้ไข
            </Button>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setOpenUpload(true);

                //setItemPic(data.row.image);
                //setItemCode(data.row.id);
              }}
            >
              เปลี่ยนรูป
            </Button>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setMsgBoxHead("ออกจากระบบ");
                setMsgBox("ยืนยันออกจากระบบ");
                handleOpenMsgBox();
              }}
              //onClick={null}
            >
              ออกจากระบบ
            </Button>
          </Grid>
        </Grid>

        <br />
        <br />
        <br />
        <br />
      </Container>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              แก้ไขข้อมูลส่วนตัว
            </Typography>
          </Toolbar>
        </AppBar>
        
        <Container maxWidth="xs" sx={{paddingTop:'80px'}}>
          <Grid container rowSpacing={1} sx={{paddingBottom:2}}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                //  type="number"
                label="รหัสผ่าน"
                variant="outlined"
                value={NewPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label="รหัส Token"
                variant="outlined"
                value={NewToken}
                onChange={(e) => {
                  setNewToken(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">คำนำหน้า</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={NewPrefix}
                  label="คำนำหน้า"
                  onChange={(e) => {
                    setNewPrefix(e.target.value);
                  }}
                >
                  <MenuItem value={"นาย"}>นาย</MenuItem>
                  <MenuItem value={"นาง"}>นาง</MenuItem>
                  <MenuItem value={"นางสาว"}>นางสาว</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ชื่อผู้ใช้"
                variant="outlined"
                value={NewFName}
                onChange={(e) => {
                  setNewFName(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="นามสกุลผู้ใช้"
                variant="outlined"
                value={NewLName}
                onChange={(e) => {
                  setNewLName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">เพศ</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={NewSex}
                  label="เพศ"
                  onChange={(e) => {
                    setNewSex(e.target.value);
                  }}
                >
                  <MenuItem value={"M"}>ชาย</MenuItem>
                  <MenuItem value={"F"}>หญิง</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  เกิดวันที่
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={NewDay}
                  label="วันที่"
                  onChange={(e) => {
                    setNewDay(e.target.value);
                  }}
                >
                  {days.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">เดือน</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={NewMonth}
                  label="วันที่"
                  onChange={(e) => {
                    setNewMonth(e.target.value);
                  }}
                >
                  {months.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ปี พ.ศ.</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={NewYear}
                  label="วันที่"
                  onChange={(e) => {
                    setNewYear(e.target.value);
                  }}
                >
                  {years.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label="โทรศัพท์"
                variant="outlined"
                value={NewTel}
                onChange={(e) => {
                  setNewTel(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="อีเมล์"
                variant="outlined"
                value={NewEmail}
                onChange={(e) => {
                  setNewEmail(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ชื่อร้าน"
                variant="outlined"
                value={NewCustName}
                onChange={(e) => {
                  setNewCustName(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ที่อยู่"
                variant="outlined"
                multiline
                rows={4}
                value={NewAddr}
                onChange={(e) => {
                  setNewAddr(e.target.value);
                }}
              />
            </Grid>

            <br />
            <Grid item xs={6} sx={{ textAlign: "center",padding:2 }}>
              <Button
                variant="contained"
                onClick={handleClose}
                fullWidth
              >
                ไปหน้าที่แล้ว
              </Button>
            </Grid>

            <Grid item xs={6} sx={{ textAlign: "center",padding:2 }}>
              <Button
                variant="contained"
                onClick={() => {
                  setMsgBoxHead("แก้ไขข้อมูลส่วนตัว");
                  setMsgBox("ยืนยันจัดเก็บ");
                  handleOpenMsgBox();
                }}
                fullWidth
              >
                จัดเก็บ
              </Button>
            </Grid>

          </Grid>
          <br/><br/><br/>      
        </Container>
      </Dialog>

      <Dialog
        open={openMsgBox}
        //onClose={}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{MsgBoxHead}</DialogTitle>
        <DialogContent>
          <DialogContentText>{MsgBox}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMsgBox} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOkMsgBox} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUpload}
        onClose={handleCloseUpload}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">รูป</DialogTitle>
        <DialogContent>
          <img
            src={process.env.REACT_APP_URL + "/upload/" + CustPic}
            width="200"
            alt=""
          />
          <br />
          <br />
          <label htmlFor="upload-image">
            <Button variant="contained" component="span">
              เปลี่ยนรูป
            </Button>

            <input
              id="upload-image"
              hidden
              accept="image/*"
              type="file"
              onChange={handleFileUpload}
            />
          </label>
          <br />
          {imageUrl && <img src={imageUrl} alt="Uploaded Image" width="200" />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpload} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOkUpload} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        //===================================== message box =============================
        open={openTokenBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{titleTokenBox}</DialogTitle>
        <DialogContent>
          <br />
          <TextField
            autoFocus={true}
            fullWidth
            type="number"
            label="ป้อนรหัส Token"
            variant="outlined"
            value={checkToken}
            onChange={(e) => {
              setCheckToken(e.target.value);
            }}
          />
          <box>{bodyTokenBox}</box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenTokenBox(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={() => {


              // console.log(memberToken);
              // console.log(checkToken);

              if (memberToken===checkToken) {
                 // setOpen(true);
                  setOpenTokenBox(false);
                  handleOpen();

              } else {
                setTitleInfoBox("");
                setBodyInfoBox("รหัส Token ไม่ถูกต้อง");
                setOpenInfoBox(true);
                   
          

              }


          }} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openInfoBox} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{titleInfoBox}</DialogTitle>
        <DialogContent>
          <DialogContentText>{bodyInfoBox}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenInfoBox(false);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>


    </div>
  );
}
