import * as React from "react";
import { useState } from "react";
import {
  TextField,
  Grid,
  Container,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import "./UserLogin.css";
import logo from "./user.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function MemberLogin() {
  const navigate = useNavigate();

  const u = localStorage.getItem("bpsoft_score_membercode");
  const p = localStorage.getItem("bpsoft_score_memberpass");

  const [UserID, setUserID] = useState(u === null ? "" : u);
  const [Password, setPassword] = useState(p);
  const [open, setOpen] = React.useState(false);

  const [openMsgBox, setOpenMsgBox] = useState(false);
  const [titleMsgBox, setTitleMsgBox] = useState("");
  const [bodyMsgBox, setBodyMsgBox] = useState("");

  async function GetPassword() {
    if (UserID === "") {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุรหัสผู้ใช้");
      setOpenMsgBox(true);
      return;
    }
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_check_password.php",
      data: { custcode: UserID, password: Password },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 0) {
          setTitleMsgBox("ไม่สำเร็จ");
          setBodyMsgBox(response.data[0].message);
          setOpenMsgBox(true);
        } else {
          setTitleMsgBox("ส่งเมล์แล้ว");
          setBodyMsgBox(response.data[0].message);
          setOpenMsgBox(true);
        }
      })
      .catch(function (error) {
        alert("error:MemberLogin");
      });
  }

  async function GetUser() {
    if (UserID === "") {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุรหัสผู้ใช้");
      setOpenMsgBox(true);
      return;
    }

    if (Password === "") {
      setTitleMsgBox("");
      setBodyMsgBox("โปรดระบุรหัสผ่าน");
      setOpenMsgBox(true);
      return;
    }

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_check_user.php",
      data: { custcode: UserID, password: Password },
    })
      .then(function (response) {
        //console.log(response.data);
        if (response.data[0].statuscode === "") {
          setOpen(true);
        } else {
          //  console.log(  response.data[0].token );
          

          localStorage.setItem("bpsoft_score_membercode", UserID);
          localStorage.setItem("bpsoft_score_memberpass", Password);

          response.data[0].statuscode === "USER"
            ? navigate("/membermain")
            : navigate("/usermain", {
                state : {
                  userid: UserID,
                  score_level : response.data[0].message
                },
              });
        }
      })
      .catch(function (error) {
        alert("error:ShowWarranty");
      });
  }

  return (
    <div>
      <Container maxWidth="xs" className="UserLogin-body">
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "center" }} className="font-link">
            <Box
              component="img"
              sx={{
                borderRadius: 2,
                //backgroundColor: "#dedede",
                width: 200,
                textAlign: "center",
              }}
              alt="The house from the offer."
              src={logo}
            />

            <Box
              sx={{
                color: "blue",
                width: 1,
                // fontWeight: "fontWeightLight",
                //fontSize: 50,
                textAlign: "center",
                fontSize: "20pt",
              }}
            >
              ระบบสะสมแต้มแบตเตอรี่
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="รหัสผู้ใช้"
              variant="outlined"
              value={UserID}
              onChange={(e) => {
                setUserID(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              type="password"
              label="รหัสผ่าน"
              variant="outlined"
              value={Password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Button fullWidth variant="contained" onClick={GetUser}>
              เข้าระบบ
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button fullWidth variant="text" onClick={GetPassword}>
              ลืมรหัสผ่าน
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              fullWidth
              variant="text"
              onClick={() => {
                navigate("/register");
              }}
            >
              สมัครสมาชิก
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"เข้าระบบไม่ได้"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            รหัสผู้ใช้ หรือ รหัสผ่าน ไม่ถูกต้อง
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            autoFocus
          >
            ตกลง
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openMsgBox} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{titleMsgBox}</DialogTitle>
        <DialogContent>
          <DialogContentText>{bodyMsgBox}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenMsgBox(false);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
