import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  DialogActions,
  DialogContentText,
  Avatar,
  Alert,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Container,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

import { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";

const columns = [
  {
    field: "id",
    headerName: "Username",
    width: 100,
    cellClassName: "DataGrid-link",
  },

  {
    field: "custname",
    headerName: "ชื่อร้านค้า",
    width: 250,
    //editable: true,
  },
  {
    field: "custgroup",
    headerName: "ประเภท",
    width: 100,
    renderCell: (params) => (params.value === "1" ? "Dealer" : "Sub dealer"),
  },
  {
    field: "fname",
    headerName: "ชื่อลูกค้า",
    width: 250,
  },
  {
    field: "custpic",
    headerName: "รูป",
    width: 60,
    //editable: true,
    renderCell: (params) => (
      <Avatar src={process.env.REACT_APP_URL + "/upload/" + params.value} />
    ), // renderCell will render the component
  },

  {
    field: "score",
    headerName: "คะแนนทั้งหมด",
    type: "number",
    renderCell: (params) => Number(params.value).toLocaleString(),
  },

  {
    field: "used",
    headerName: "คะแนนที่ใช้",
    type: "number",
    renderCell: (params) => Number(params.value).toLocaleString(),
  },

  {
    field: "bal",
    headerName: "คงเหลือ",
    type: "number",
    renderCell: (params) => Number(params.value).toLocaleString(),
  },
];

export default function UserMember() {
  const [ItemCode, setItemCode] = React.useState("");
  const [CustGroup, setCustGroup] = React.useState("1");
  // const [ItemName, setItemName] = React.useState("");
  const [ItemPic, setItemPic] = React.useState(null);
  const [Data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  // const [score, setScore] = React.useState(0);
  const [reload, setReload] = React.useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);

  //==================== Edit Form ======================
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);

  const [id, setId] = React.useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [NewToken, setNewToken] = useState("");
  const [NewCustCode, setNewCustCode] = useState("");
  const [NewCustName, setNewCustName] = useState("");
  const [NewPrefix, setNewPrefix] = useState("");
  const [NewFName, setNewFName] = useState("");
  const [NewLName, setNewLName] = useState("");
  const [NewSex, setNewSex] = useState("");

  const [NewAddr, setNewAddr] = useState("");
  const [NewTel, setNewTel] = useState("");
  const [NewEmail, setNewEmail] = useState("");

  const [NewDay, setNewDay] = useState(1);
  const [NewMonth, setNewMonth] = useState(1);
  const [NewYear, setNewYear] = useState(2500);

  const [currentScore, setCurrentScore] = useState(0);
  const [currentUsed, setCurrentUsed] = useState(0);

  // ====================================================

  const [confirmBoxOpen, setConfirmBoxOpen] = React.useState(false);
  const [confirmBoxBody, setConfirmBoxBody] = React.useState("");
  const [confirmBoxHead, setConfirmBoxHead] = React.useState("");

  const [infoBoxOpen, setInfoBoxOpen] = React.useState(false);
  const [infoBoxBody, setInfoBoxBody] = React.useState("");
  const [infoBoxHead, setInfoBoxHead] = React.useState("");

  let i = 0;
  let days = [];
  for (i = 1; i <= 31; i++) {
    days.push(i);
  }

  let years = [];
  for (i = 2500; i <= 2550; i++) {
    years.push(i);
  }
  let months = [
    { id: 1, name: "มกราคม" },
    { id: 2, name: "กุมภาพันธ์" },
    { id: 3, name: "มีนาคม" },
    { id: 4, name: "เมษายน" },
    { id: 5, name: "พฤษภาคม" },
    { id: 6, name: "มิถุนายน" },
    { id: 7, name: "กรกฎาคม" },
    { id: 8, name: "สิงหาคม" },
    { id: 9, name: "กันยายน" },
    { id: 10, name: "ตุลาคม" },
    { id: 11, name: "พฤศจิกายน" },
    { id: 12, name: "ธันวาคม" },
  ];

  async function UpdateItemPic() {
    setLoading(true);

    // event.preventDefault();
    const formData = new FormData();
    formData.append("itempic", file);
    formData.append("itemcode", ItemCode);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_upd_itempic.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 1) {
          //GetItem();
          setReload(reload + 1);
        } else {
          alert(response.data[0].message);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  async function UpdateItem() {
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_upd_custgroup.php",
      data: { custcode: selectedRows, custgroup: CustGroup },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 1) {
          //console.log(response.data[0].message);
          GetItem();
        } else {
          alert(response.data[0].message);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  async function GetItem() {
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_all_member.php",
      data: {},
    })
      .then(function (response) {
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function UpdatMember() {
    const formData = new FormData();

    setConfirmBoxOpen(false);

    const d = NewDay < 10 ? "0" + NewDay : NewDay;
    const m = NewMonth < 10 ? "0" + NewMonth : NewMonth;

    formData.append("custcode", id);
    formData.append("newcustcode", NewCustCode);
    formData.append("custname", NewCustName);
    formData.append("password", NewPassword);
    formData.append("token", NewToken);
    formData.append("prefix", NewPrefix);
    formData.append("fname", NewFName);
    formData.append("lname", NewLName);
    formData.append("sex", NewSex);
    formData.append("addr", NewAddr);
    formData.append("birthday", NewYear + "-" + m + "-" + d);
    formData.append("tel", NewTel);
    formData.append("email", NewEmail);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_upd_member.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        // console.log(response);
        if (response.data[0].statuscode === 1) {
          //setOpenMsgBox(false);
          setOpenForm(false);
          //CheckUser();
          setReload(reload + 1);
        } else {
        }
      })
      .catch(function (error) {
        alert(error);
      });
  }

  async function delMember() {
    //setLoading(true);

    setConfirmBoxOpen(false);

    const formData = new FormData();

    formData.append("id", id);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_del_member.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        //console.log(response);

        if (response.data[0].statuscode === 1) {
          setOpenForm(false);
          setReload(reload + 1);
        } else {
          setInfoBoxHead("ลบไม่ได้");
          setInfoBoxBody(response.data[0].message);
          setInfoBoxOpen(true);
        }
        setLoading(false);
      })
      .catch(function (error) {
        alert(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    GetItem();
  }, [reload]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOk = () => {
    setOpen(false);
    UpdateItem();
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
  };
  const handleOkUpload = () => {
    setOpenUpload(false);
    UpdateItemPic();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
      setFile(file);
      //console.log(reader.result)
    };
    reader.readAsDataURL(file);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            fileName: "สมาชิก",
            // delimiter: ";",
            utf8WithBom: true,
          }}
          printOptions={{
            fileName: "สมาชิก",
            hideFooter: true,
            hideToolbar: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <Container maxWidth={false}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          backgroundColor: "#ffffff",
          borderRadius: 2,
        }}
      >
        <DataGrid
          rows={Data}
          columns={columns}
          slots={{
            toolbar: CustomToolbar,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          checkboxSelection
          disableRowSelectionOnClick
          loading={loading}
          onRowSelectionModelChange={(data) => {
            setSelectedRows(data);
            //console.log(data);
          }}
          onCellClick={(data) => {
            // console.log(data);

            if (data.field === "id") {
              

              // setNewPassword(data.row.pic);
              // setNewToken(data.row.id);

              setId(data.row.id);
              setNewCustCode(data.row.id);
              setNewPassword(data.row.password);
              setNewToken(data.row.token);
              setNewPrefix(data.row.prefix);
              setNewFName(data.row.fname);
              setNewLName(data.row.lname);
              setNewSex(data.row.sex);
              setNewCustName(data.row.custname);
              setNewAddr(data.row.addr);
              setNewTel(data.row.tel);
              setNewEmail(data.row.email);

              setCurrentScore(data.row.score);
              setCurrentUsed(data.row.used);

              setNewDay(Number(data.row.birthday.substr(8, 2)));
              setNewMonth(Number(data.row.birthday.substr(5, 2)));
              setNewYear(Number(data.row.birthday.substr(0, 4)));

              setOpenForm(true);

              // setImageUrl(null);
            }
          }}
        />
        <Alert severity="info">คลิกที่ Username เมื่อต้องการแก้ไข</Alert>
        <Button
          variant="contained"
          disabled={selectedRows.length === 0 ? true : false}
          onClick={handleClickOpen}
        >
          เปลี่ยนประเภท
        </Button>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">เปลี่ยนประเภท</DialogTitle>
        <DialogContent>
          <DialogContentText>
            สำหรับ {selectedRows.length} รายการที่เลือก
          </DialogContentText>

          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              เปลี่ยนเป็น
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={CustGroup}
              onChange={(e) => {
                setCustGroup(e.target.value);
              }}
            >
              <FormControlLabel value="1" control={<Radio />} label="Dealer" />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Sub dealer"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUpload}
        onClose={handleCloseUpload}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          รูปแบตเตอรี่ {ItemCode}
        </DialogTitle>
        <DialogContent>
          <img src={process.env.REACT_APP_URL + "/upload/" + ItemPic} alt="" />
          <br />
          <br />
          <label htmlFor="upload-image">
            <Button variant="contained" component="span">
              เปลี่ยนรูป
            </Button>

            <input
              id="upload-image"
              hidden
              accept="image/*"
              type="file"
              onChange={handleFileUpload}
            />
          </label>
          <br />
          {imageUrl && <img src={imageUrl} alt="Uploaded Image" width="200" />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpload} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOkUpload} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        // fullScreen
        open={openForm}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title">
          {id === "" ? "เพิ่ม" : "แก้ไข"}ข้อมูลสมาชิก
        </DialogTitle>

        <Container maxWidth="xs">
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <TextField
                disabled={(parseInt(currentScore) !== 0)}
                fullWidth
                //  type="number"
                label="Username"
                variant="outlined"
                value={NewCustCode}
                onChange={(e) => {
                  setNewCustCode(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                //  type="number"
                label="Password"
                variant="outlined"
                value={NewPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label="รหัส Token"
                variant="outlined"
                value={NewToken}
                onChange={(e) => {
                  setNewToken(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">คำนำหน้า</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={NewPrefix}
                  label="คำนำหน้า"
                  onChange={(e) => {
                    setNewPrefix(e.target.value);
                  }}
                >
                  <MenuItem value={"นาย"}>นาย</MenuItem>
                  <MenuItem value={"นาง"}>นาง</MenuItem>
                  <MenuItem value={"นางสาว"}>นางสาว</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ชื่อผู้ใช้"
                variant="outlined"
                value={NewFName}
                onChange={(e) => {
                  setNewFName(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="นามสกุลผู้ใช้"
                variant="outlined"
                value={NewLName}
                onChange={(e) => {
                  setNewLName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">เพศ</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={NewSex}
                  label="เพศ"
                  onChange={(e) => {
                    setNewSex(e.target.value);
                  }}
                >
                  <MenuItem value={"M"}>ชาย</MenuItem>
                  <MenuItem value={"F"}>หญิง</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  เกิดวันที่
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={NewDay}
                  label="วันที่"
                  onChange={(e) => {
                    setNewDay(e.target.value);
                  }}
                >
                  {days.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">เดือน</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={NewMonth}
                  label="วันที่"
                  onChange={(e) => {
                    setNewMonth(e.target.value);
                  }}
                >
                  {months.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ปี พ.ศ.</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={NewYear}
                  label="วันที่"
                  onChange={(e) => {
                    setNewYear(e.target.value);
                  }}
                >
                  {years.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label="โทรศัพท์"
                variant="outlined"
                value={NewTel}
                onChange={(e) => {
                  setNewTel(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="อีเมล์"
                variant="outlined"
                value={NewEmail}
                onChange={(e) => {
                  setNewEmail(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ชื่อร้าน"
                variant="outlined"
                value={NewCustName}
                onChange={(e) => {
                  setNewCustName(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ที่อยู่"
                variant="outlined"
                multiline
                rows={4}
                value={NewAddr}
                onChange={(e) => {
                  setNewAddr(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <DialogActions>
            <Button
              disabled={id === 0}
              onClick={() => {
                setConfirmBoxHead("ยืนยัน");
                setConfirmBoxBody("ลบข้อมูลสมาชิก");
                setConfirmBoxOpen(true);
              }}
              color="primary"
            >
              DELETE
            </Button>

            <Button onClick={() => setOpenForm(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                setConfirmBoxHead("ยืนยัน");
                setConfirmBoxBody("แก้ไขข้อมูลสมาชิก");
                setConfirmBoxOpen(true);
              }}
              color="primary"
            >
              SAVE
            </Button>
          </DialogActions>
        </Container>
      </Dialog>

      <Dialog open={confirmBoxOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{confirmBoxHead}</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmBoxBody}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmBoxOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              confirmBoxBody === "แก้ไขข้อมูลสมาชิก"
                ? UpdatMember()
                : delMember();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={infoBoxOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{infoBoxHead}</DialogTitle>
        <DialogContent>
          <DialogContentText>{infoBoxBody}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setInfoBoxOpen(false);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
