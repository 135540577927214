import * as React from "react";

import { Route, Routes } from "react-router-dom";

import UserLogin from "./UserLogin";
import UserMain from "./UserMain";
import Register from "./Register";
import MemberLogin from "./MemberLogin";
import MemberMain from "./MemberMain";
import ShowWarranty from "./ShowWarranty";
import "./App.css";
import Main from "./Main";
import QRScan from "./MemberQR";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/userlogin" element={<UserLogin />} />
      <Route path="/usermain" element={<UserMain />} />
      <Route path="/register" element={<Register />} />
      <Route path="/memberlogin" element={<MemberLogin />} />
      <Route path="/membermain" element={<MemberMain />} />
      <Route path="/showwarranty" element={<ShowWarranty />} />
      <Route path="/memberqr" element={<QRScan />} />
    </Routes>
  );
}
