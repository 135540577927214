import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
  Alert,
  Avatar,
  Fab,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";

import { useEffect, useState } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./App.css";

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 50,
    cellClassName: 'DataGrid-link',
  },

  {
    field: "topic",
    headerName: "ชื่อเรื่อง",
    width: 500,
  },


  {
    field: "pic_title",
    headerName: "รูป",
    width: 100,
    renderCell: (params) => (
      <Avatar src={process.env.REACT_APP_URL + "/upload/" + params.value} />
    ),
  },
  {
    field: "startdate",
    headerName: "เริ่มวันที่",
    width: 100,
  },
  {
    field: "enddate",
    headerName: "ถึงวันที่",
    width: 100,
  },


  {
    field: "isstatus",
    headerName: "แสดง",

    renderCell: (params) => (
      params.value==='Y' ? <img src={process.env.REACT_APP_URL + "/images/ok.gif"} alt='' /> : ''
    ),
  },
];

export default function UserPro() {
  const today = new Date();
  let d = today.getDate();
  let m = today.getMonth() + 1;
  let y = today.getFullYear();

  if (d < 10) {
    d = "0" + d;
  }
  if (m < 10) {
    m = "0" + m;
  }

  y = y + "-" + m + "-" + d;

  const [id, setId] = React.useState(0);
  const [topic, setTopic] = React.useState("");
  const [descriptions, setDescriptions] = React.useState("");
  const [startdate, setStartdate] = React.useState(y);
  const [enddate, setEnddate] = React.useState(y);
  const [isstatus, setIsstatus] = React.useState(false);
  const [file, setFile] = useState(null);
  //const [file2, setFile2] = useState(null);

  //const [picTopic, setPicTopic] = React.useState(null);
  //const [picDetail, setPicDetail] = React.useState(null);

  const [ItemCode, setItemCode] = React.useState("");
  

  const [ItemPic, setItemPic] = React.useState(null);

  const [Data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);

  const [reload, setReload] = React.useState(0);
  const [imageUrl, setImageUrl] = useState(null);

  const [openDelete, setOpenDelete] = React.useState(false);
  const [openInfoBox, setOpenInfoBox] = React.useState(false);
  const [titleInfoBox, setTitleInfoBox] = React.useState("");
  const [bodyInfoBox, setBodyInfoBox] = React.useState("");

  const modules = {
    clipboard: {
      matchVisual: false,
    },
    // other modules...
  };


  async function saveDatabase() {
    setLoading(true);

    const formData = new FormData();

    formData.append("id", id);
    formData.append("topic", topic);
    formData.append("descriptions", descriptions);
    formData.append("startdate", startdate);
    formData.append("enddate", enddate);
    formData.append("isstatus", isstatus ? "Y" : "N");
    formData.append("pic_title", file);
   // formData.append("pic_detail", file2);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_ins_pro.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data[0].statuscode === 1) {
          setOpenForm(false);
          setReload(reload + 1);
        } else {
          alert(response.data[0].message);
        }
        setLoading(false);
      })
      .catch(function (error) {
        alert(error);
        //console.log(error);
        setLoading(false);
      });
  }


  async function delDatabase() {
    setLoading(true);

    const formData = new FormData();

    formData.append("id", id);

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_del_pro.php",
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(function (response) {
        //console.log(response);

        if (response.data[0].statuscode === 1) {
          // GetItem();

          setOpenForm(false);
          setOpenDelete(false);
          setReload(reload + 1);
        } else {

          setTitleInfoBox('ลบไม่ได้');
          setBodyInfoBox(response.data[0].message)
          setOpenDelete(false);
          setOpenInfoBox(true);
          
        }
        setLoading(false);
      })
      .catch(function (error) {
        alert(error);
        setLoading(false);
      });
  }


  async function GetItem() {
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_pro.php",
      data: { itemcode: '', itemname: ''},
    })
      .then(function (response) {
        //console.log(response.data);
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    setStartdate(y);
    GetItem();
  }, [reload]);

  const handleClickOpen = () => {
    setOpen(true);
  };

 


  const handleCloseUpload = () => {
    setOpenUpload(false);
  };
  const handleOkUpload = () => {
    setOpenUpload(false);
    //UpdateItemPic();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
      setFile(file);
      //console.log(reader.result)
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          backgroundColor: "#ffffff",
          borderRadius: 2,
        }}
      >
        <DataGrid
          rows={Data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          //checkboxSelection
          disableRowSelectionOnClick
          loading={loading}
          onRowSelectionModelChange={(data) => {
            setSelectedRows(data);
            //console.log(data);
          }}
          onCellClick={(data) => {
            if (data.field === "pic") {
              //setOpenUpload(true);
              //setItemPic(data.row.pic);
              //setItemCode(data.row.id);
            }
            if (data.field === "id") {
              setOpenForm(true);

              setItemPic(data.row.pic_title);
              setId(data.row.id);
              setDescriptions(data.row.descriptions);
              setTopic(data.row.topic);
              setStartdate(data.row.startdate);
              setEnddate(data.row.enddate);
              setIsstatus(data.row.isstatus === "Y" ? true : false);
              setImageUrl(null);
            }
          }}
        />
        <Alert severity="info">
          คลิกที่รหัสเมื่อต้องการแก้ไข
        </Alert>        
 
      </Box>

      <Dialog
        open={openUpload}
        onClose={handleCloseUpload}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          รูปของรางวัล {ItemCode}
        </DialogTitle>
        <DialogContent>
          <img
            src={process.env.REACT_APP_URL + "/upload/" + ItemPic}
            height="200"
          />
          <br />
          <br />
          <label htmlFor="upload-image">
            <Button variant="contained" component="span">
              เปลี่ยนรูป
            </Button>

            <input
              id="upload-image"
              hidden
              accept="image/*"
              type="file"
              onChange={handleFileUpload}
            />
          </label>
          <br />
          {imageUrl && <img src={imageUrl} alt="Uploaded Image" width="200" />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpload} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOkUpload} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openForm}
        onClose={handleCloseUpload}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {id === 0 ? "เพิ่ม" : "แก้ไข"}ข่าวสาร/โปรโมชั่น
        </DialogTitle>
        <DialogContent>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ชื่อเรื่อง"
                variant="outlined"
           
                value={topic}
                onChange={(e) => {
                  setTopic(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
  
            <ReactQuill
                theme="snow"
                value={descriptions}
                modules={modules}
                onChange={setDescriptions}
              />

            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type="date"
                label="เริ่มวันที่"
                variant="outlined"
                value={startdate}
                onChange={(e) => {
                  setStartdate(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type="date"
                label="ถึงวันที่"
                variant="outlined"
                value={enddate}
                onChange={(e) => {
                  setEnddate(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={isstatus} />}
                label="แสดงโปรโมชั่นนี้"
                onChange={(e) => {
                  setIsstatus(e.target.checked);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              {ItemPic && (
                <img
                  src={process.env.REACT_APP_URL + "/upload/" + ItemPic}
                  height="200"
                  style={{ borderRadius: "20px" }}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <label htmlFor="upload-image">
                <Button variant="contained" component="span">
                  เลือกรูป
                </Button>

                <input
                  id="upload-image"
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleFileUpload}
                />
              </label>
            </Grid>

            <Grid item xs={12}>
              {imageUrl && (
                <img
                  src={imageUrl}
                  alt="Uploaded Image"
                  height="200"
                  style={{ borderRadius: "20px" }}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={id === 0}
            onClick={() => setOpenDelete(true)}
            color="primary"
          >
            DELETE
          </Button>

          <Button onClick={() => setOpenForm(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={saveDatabase} color="primary">
            SAVE
          </Button>
        </DialogActions>

      </Dialog>

      <Dialog
        open={openDelete}
        //onClose={}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">ยืนยัน</DialogTitle>
        <DialogContent>
          <DialogContentText>ลบข่าวสาร/โปรโมชั่น</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDelete(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={delDatabase} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={openInfoBox} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{titleInfoBox}</DialogTitle>
        <DialogContent>
          <DialogContentText>{bodyInfoBox}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenInfoBox(false);
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>      


      <Fab
        onClick={() => {
          setOpenForm(true);
          setId(0);
          setTopic("");
          setDescriptions("");
          //setStartdate(y);
          //setEnddate(y);
          setItemPic("");
          setFile(null);
          setImageUrl(null);
        }}
        color="primary"
        aria-label="add"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
      >
        <AddIcon />
      </Fab>
    </>
  );
}
