import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  DialogActions,
  Avatar,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Container,
} from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";

import { useEffect } from "react";
import axios from "axios";
import "./App.css";

const columns = [
  {
    field: "datecreate",
    headerName: "วันที่",
    width: 160,
    //editable: true,
  },
  {
    field: "descriptions",
    headerName: "รายการ",
    width: 250,
  },
  {
    field: "pic",
    headerName: "รูป",
    width: 60,
    //editable: true,
    renderCell: (params) => (
      <Avatar src={process.env.REACT_APP_URL + "/upload/" + params.value} />
    ), // renderCell will render the component
  },
  {
    field: "custname",
    headerName: "ชื่อร้านค้า",
    width: 200,
  },
  {
    field: "username",
    headerName: "ชื่อลูกค้า",
    width: 200,
  },
  {
    field: "addr",
    headerName: "ที่อยู่",
    width: 200,
  },
  {
    field: "tel",
    headerName: "โทรศัพท์",
    width: 100,
  },  
  {
    field: "statusname",
    headerName: "สถานะ",
    cellClassName: 'DataGrid-link',
  },
  {
    field: "remark",
    headerName: "รายละเอียด",
    width: 200,
  },
];

export default function UserRedeem() {
  const [CustName, setCustName] = React.useState("");
  const [FName, setFName] = React.useState("");

  const [Data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [redeemId, setRedeemId] = React.useState(0);
  const [custCode, setCustCode] = React.useState();
  const [IsStatus, setIsStatus] = React.useState("1");
  const [newStatus, setNewStatus] = React.useState("1");
  const [newRemark, setNewRemark] = React.useState("");
  const [reload, setReload] = React.useState(0);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            fileName: "แลกของรางวัล",
           // delimiter: ";",
            utf8WithBom: true,
          }}
          printOptions={{
            fileName:"แลกของรางวัล",
            hideFooter: true,
            hideToolbar: true,
          }}          
        />
      </GridToolbarContainer>
    );
  }


  async function UpdateRedeem() {
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_upd_redeem.php",
      data: {
        redeemid: redeemId,
        isstatus: newStatus,
        remark: newRemark,
        custcode: custCode,
      },
    })
      .then(function (response) {
        //console.log(response);

        if (response.data[0].statuscode === 1) {
          //console.log(response.data[0].message);
          setOpen(false);
          setReload(reload + 1);
        } else {
          alert(response.data[0].message);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  async function GetItem() {
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_all_redeem.php",
      data: {custname : CustName, fname :FName, isstatus : IsStatus},
    })
      .then(function (response) {
       //console.log(response);
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    GetItem();
  }, [reload]);

  const handleClickOpen = () => {
    setOpen(true);
  };


  return (
    <Container maxWidth={false}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          //backgroundColor: "#dedede",
          borderRadius: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="ชื่อร้านค้า"
              variant="outlined"
              value={CustName}
              onChange={(e) => {
                setCustName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="ชื่อลูกค้า"
              variant="outlined"
              value={FName}
              onChange={(e) => {
                setFName(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">สถานะ</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={IsStatus}
                label="สถานะ"
                onChange={(e) => {
                  setIsStatus(e.target.value);
                }}
              >
                <MenuItem value={"1"}>รับแลกรางวัล</MenuItem>
                <MenuItem value={"2"}>กำลังจัดส่ง</MenuItem>
                <MenuItem value={"3"}>จัดส่งแล้ว</MenuItem>
                <MenuItem value={"4"}>แสดงทุกสถานะ</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <Button variant="contained" onClick={() => {setReload(reload + 1)}}>
              แสดง
            </Button>
          </Grid>
        </Grid>

        <br />

        <Box
          sx={{
            height: "100%",
            width: "100%",
            backgroundColor: "#ffffff",
            borderRadius: 2,
          }}
        >
          <DataGrid
            rows={Data}
            columns={columns}
            slots={{
              toolbar: CustomToolbar,
            }}
  
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            //checkboxSelection
            disableRowSelectionOnClick
            loading={loading}
            onRowSelectionModelChange={(data) => {
              setSelectedRows(data);
              //console.log(data);
            }}
            onCellClick={(data) => {
              setRedeemId(data.row.id);
              setCustCode(data.row.custcode);
              setNewStatus(data.row.isstatus);
              setNewRemark(data.row.remark);
              data.field === "statusname" ? setOpen(true) : setOpen(false);
            }}
          />

          <Alert severity="info">คลิกสถานะเมื่อต้องการเปลี่ยนสถานะ</Alert>
        </Box>
      </Box>

      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">สถานะจัดส่ง</DialogTitle>
        <DialogContent>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">สถานะ</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={newStatus}
                  label="สถานะ"
                  onChange={(e) => {
                    setNewStatus(e.target.value);
                  }}
                >
                  <MenuItem value={"1"}>รับแลกรางวัล</MenuItem>
                  <MenuItem value={"2"}>กำลังจัดส่ง</MenuItem>
                  <MenuItem value={"3"}>จัดส่งแล้ว</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="รายละเอียด"
                variant="outlined"
                multiline
                maxRows={4}
                value={newRemark}
                onChange={(e) => {
                  setNewRemark(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={UpdateRedeem} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
