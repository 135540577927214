import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { useEffect } from "react";
import axios from "axios";

import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import RedeemIcon from "@mui/icons-material/Redeem";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CategoryIcon from "@mui/icons-material/Category";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import ChargingStationIcon from "@mui/icons-material/ChargingStation";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";

import UserPage from "./UserPage";
import logo from "./logo.png";


import { useLocation } from "react-router-dom";

const drawerWidth = 240;


const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function UserMain() {
  const theme = useTheme();

  const [open, setOpen] = React.useState(true);
  const [menu, setMenu] = React.useState("1000");
  const [menuList, setMenuList] = React.useState([]);
  const [menuActive, setMenuActive] = React.useState("");

  const [openCollapse, setOpenCollapse] = React.useState(false);
  

  const location = useLocation();
  //function handleOpenSettings() {
  //    setOpenCollapse(!openCollapse);
  //  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const myIcon = [
    <HomeIcon />,
    <QrCode2Icon />,
    <PersonIcon />,
    <ElectricCarIcon />,
    <CategoryIcon />,
    <RedeemIcon />,
    <NewspaperIcon />,
    <ChargingStationIcon />,
    <EmojiEventsIcon />,
    <ReportProblemIcon />,
    <NotificationsActiveIcon />,
  ];

  async function GetMenu() {
    //setLoading(true);

   
   // console.log(  location.state );

    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_menu.php",
      data : {userid : location.state.userid, score_level : location.state.score_level }
    })
      .then(function (response) {
        console.log(response);

        setMenuList(response.data);
        // setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    GetMenu();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            ระบบสะสมแต้ม - {menuActive}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box
            component="img"
            sx={{
              //height: 233,
              borderRadius: 2,
              width: "80%",
              textAlign: "right",
              //maxHeight: { xs: 233, md: 167 },
              //maxWidth: { xs: 350, md: 250 },
            }}
            src={logo}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>
          {menuList.map((text) => (
            <ListItem
              key={text.menuid}
              disablePadding
              onClick={() => {
                setMenu(text.id);
                setMenuActive(text.menuname);
              }}
            >
              <ListItemButton>
                <ListItemIcon>{myIcon[text.iconid]}</ListItemIcon>
                <ListItemText primary={text.menuname} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Main open={open}>
        <DrawerHeader />
        <UserPage index={menu} />
      </Main>
    </Box>
  );
}
