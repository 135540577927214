import * as React from "react";

import MemberLogin from "./MemberLogin";
import ShowWarranty from "./ShowWarranty";

export default function Main() {


  return <MemberLogin />
  /*
  const queryParameters = new URLSearchParams(window.location.search);
  const param = queryParameters.get("k");

  //console.log(param);

  
  switch (param) {
    case null:
      return <MemberLogin />;    
    default:
      return <ShowWarranty />;
  }
  */
  
}
