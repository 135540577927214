import * as React from "react";
import { useEffect } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Box,
  CardMedia,
} from "@mui/material";

import "./UserLogin.css";
import "./MemberMain.css";
import axios from "axios";

//  use point screen

export default function MemberUsed() {
  //const [score, setScore] = React.useState(0);

  const [itemData, setItemData] = React.useState([]);

  const u = localStorage.getItem("bpsoft_score_membercode");

  async function GetItem() {
    //setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_redeem.php",
      data: { custcode: u },
    })
      .then(function (response) {
        setItemData(response.data);
        //setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    GetItem();
  }, [0]);
  //
  return (
    <div>
      {itemData.map((item) => (
        <Grid container spacing={1} sx={{ paddingTop: 2 }}>
          <Grid
            // item
            className="font-link"
            xs={6}
            key={item.id}
            onClick={() => {}}
          >
            <CardMedia
              component="img"
              sx={{

                border: 1,
                borderColor: "grey.500",
                //borderRadius: "5px",                
                //paddingTop: '100%',
                //objectFit: 'cover',
                // width: "200px",
               // height: "180px",
                // textAlign: "center",
                //margin: "auto",
              }}
              alt={""}
              src={
                item.pic === ""
                  ? process.env.REACT_APP_URL + "/upload/giftbox.png"
                  : process.env.REACT_APP_URL + "/upload/" + item.pic
              }
            />
          </Grid>
          <Grid //</div>
            //item
            className="font-link"
            xs={6}
            sx={{ paddingLeft: 2 }}
          >
            <Box
              sx={{
                color: "#808080",
                fontSize: "10pt",
              }}
            >
              {item.datecreate}
            </Box>

            <Box
              sx={{
                //color: "#808080",
                fontSize: "12pt",
              }}
            >
              {item.topic}
            </Box>

            <Box
              sx={{
                color: "red",
                fontSize: "12pt",
              }}
            >
              {Number(item.score).toLocaleString()} คะแนน

            </Box>
            <Box
              sx={{
                color: "#808080",
                fontSize: "10pt",
              }}
            >
              สถานะ {item.isstatus}
              <br/>
              {item.remark}
            </Box>
          </Grid>
        </Grid>
      ))}

   
      <br />
      <br />
      <br />
    </div>
  );
}
