import * as React from 'react';
import MemberUse from './MemberUse';
import MemberHis from './MemberHis';
import MemberEdit from './MemberEdit';
import MemberSms from './MemberSms';
import QRScan from './MemberQR';

export default function MemberPage(props) {

    if (props.index===0) {
        return <MemberUse/>
    }
    if (props.index===1) {
        return <MemberHis/>
    }

    if (props.index===2) {
        return <QRScan/>
    }    
    if (props.index===3) {
        return <MemberSms/>
    }
        
    if (props.index===4) {
        return <MemberEdit/>
    }

}    