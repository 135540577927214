import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Register from "./Register";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryParameters = new URLSearchParams(window.location.search);
const param = queryParameters.get("r");

//console.log(param);

if (param === "Y") {
  root.render(
    <BrowserRouter basename="/point">
      <React.StrictMode>
        <Register />
      </React.StrictMode>
    </BrowserRouter>
  );
} else {
  root.render(
    <BrowserRouter basename="/">
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
